import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { Box, Grid, Tooltip, useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { renderMoney, renderPercent } from "oldcomponents/utils";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IPropsModalProjectDetails {
    open: boolean,
    onClose: Function,
    info: any,
    summary: any,
    kpi: any,
    tab: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            { value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            ) }
        </div>
    )
}

const InfoItem = (props: { name: string, value: any, color?: any }) => {
    return (
        <Box>
            <h5 style={{ marginBottom: 5, color: props.color }}>{props.name}</h5>
            {props.value}
        </Box>
    )
}

const KpiItem = (props: { name: string, value: any, color?: any, tooltip?: string }) => {
    return (
        <Box>
            <h5 style={{ marginBottom: 5 }}>{props.name}</h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ display: 'inline-block', width: 110 }}>{props.value}</span>
                {props.tooltip && (<Tooltip title={props.tooltip}>
                    <InfoIcon color="primary" style={{ marginLeft: 10, color: props.color }} />
                </Tooltip>)}
            </div>
        </Box>
    )
}

const SummaryItem = (props: { name: string, value: any }) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
            <h5 style={{ margin: 0, marginBottom: 5 }}>{props.name}</h5>
            <h3 style={{ margin: 0, marginLeft: 'auto'}}>{props.value}</h3>
        </Box>
    )
}

const SummarySubItem = (props: { name: string, value: any }) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
            <div style={{ paddingLeft: 20, fontSize: '0.83em', margin: 0, marginBottom: 5 }}>{props.name}</div>
            <h5 style={{ margin: 0, marginLeft: 'auto'}}>{props.value}</h5>
        </Box>
    )
}

export default function ModalProjectDetails(props: IPropsModalProjectDetails) {
    const theme: any = useTheme() // todo - replace any

    const [open, setOpen] = useState<boolean>(props.open)
    const [tab, setTab] = React.useState(props.tab);

    const VoqinColorSuccess = theme?.palette?.success?.main || ''
    const VoqinColorError = theme?.palette?.error?.main || ''

    useEffect(() => setOpen(props.open), [props.open])

    useEffect(() => setTab(props.tab), [props.tab])

    const onClose = (): void => props.onClose()

    const onTabChange = (e: React.SyntheticEvent, newValue: number) => setTab(newValue)

    const getTabProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ padding: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={onTabChange}>
                            <Tab label="Project Info" {...getTabProps(0)} />
                            <Tab label="Project Summary" {...getTabProps(1)} />
                            <Tab label="KPI" {...getTabProps(2)} />
                        </Tabs>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ padding: 4 }}>
                    <TabPanel value={tab} index={0}>
                        <Grid container>
                            <Grid item xs={6}>
                                <InfoItem name="Event name" value={props.info.name} />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoItem name="Event number" value={props.info.eventNumber} />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoItem name="Client" value={props.info.client_name} />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoItem name="Location" value={props.info.client_location_name} />
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={6}>
                                <InfoItem name="Client tax" value={renderPercent(props.info.taxClient / 100)} />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoItem name="Commission tax" value={renderPercent(props.info.taxComission / 100)} />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoItem name="Number of participants" value={props.info.participants} />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoItem name="Payable tax" value={renderPercent(props.info.taxPayable / 100)} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <SummaryItem name="Total project T/O" value={renderMoney(props.summary.totalTO)} />
                        <SummaryItem name="Exchange rate gain / loss" value={renderMoney(props.summary.rateGain)} />
                        <SummaryItem name="Total T/O" value={renderMoney(props.summary.totalTO)} />
                        <SummaryItem name="Total costs" value={renderMoney(props.summary.totalCosts)} />
                        <SummarySubItem name="Total Project Costs" value={renderMoney(props.summary.costs?.project)} />
                        <SummarySubItem name="Total Pitching Costs" value={renderMoney(props.summary.costs?.pitching)} />
                        <SummarySubItem name="Total Operational Costs" value={renderMoney(props.summary.costs?.operational)} />
                        <SummarySubItem name="Total Sales Offices Costs" value={renderMoney(props.summary.costs?.salesOffices)} />
                        <SummarySubItem name="Total Client Costs" value={renderMoney(props.summary.costs?.client)} />
                        <SummaryItem name="Gross profit" value={renderMoney(props.summary.gp)} />
                        <SummaryItem name="Gross profit - margin" value={renderPercent(props.summary.gpMargin)} />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Grid container>
                            <Grid item xs={6}>
                                <KpiItem name="Speed of conversion" tooltip="Below the average speed of conversion" value={props.kpi.convSpeed} color={VoqinColorSuccess} />
                            </Grid>
                            <Grid item xs={6}>
                                <KpiItem name="Number of interactions" tooltip="Above the average number of client interactions" value={props.kpi.clientInteractions} color={VoqinColorError} />
                            </Grid>
                            <Grid item xs={6}>
                                <KpiItem name="Efficiency" tooltip={`${props.kpi.approved} of ${props.kpi.suppliers} supplier proposals approved by client`} value={renderPercent(props.kpi.efficiency)} />
                            </Grid>
                            <Grid item xs={6}>
                                <KpiItem name="Inefficiency" tooltip={`${props.kpi.rejected} of ${props.kpi.suppliers} supplier proposals rejected by client`} value={renderPercent(props.kpi.inefficiency)} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </DialogContent>
                <DialogActions sx={{ padding: 4 }}>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}