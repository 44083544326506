import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';
import EditForm from 'components/EditForm';
import { Row } from './row';
import Column from './column';
import { formConfig } from './formConfig';
import { useNavigate } from 'react-router-dom';
import routes from 'enums/routes';

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const ChiefClevel = () => {
    const [checked, setCheck] = useState<number[]>([]);
    const handleSelect = (rowIds: number[]) => setCheck(rowIds);
    const [formOpen, setFormOpen] = useState<boolean>(false);

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const selectedRow = selectedRowIndex !== -1 ? Row[selectedRowIndex] : null;

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormConfirm = () => {
      handleFormClose()
    }

    const initialValues = useMemo(() => {
        if (selectedRow) {
            return {
                question_code: selectedRow.question_code || '',
                header: selectedRow.header || '',
                question: selectedRow.question || '',
                order: selectedRow.order || ''
            };
        }
        return {
            question_code: '',
            header: '',
            question: '',
            order: ''
        };
    }, [selectedRowIndex]);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`${routes.Voqinway}/${routes.VoqinSetup}/${routes.DefaultEvaluations}/${routes.ChiefCLevel}`)
    }

    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
                <Breadcrumbs texts={['Default Questionaires', 'Chief Level']} />
            </Box>
 
            <Box style={{ height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex' }}>
               
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={Row}
                    columns={Column.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                    onRowClick={onClick}
                />

                <EditForm
                    open={formOpen}
                    handleClose={handleFormClose}
                    handleConfirm={handleFormConfirm}
                    title={'Edit Voqin Way'}
                    config={formConfig}
                    initialValues={initialValues}
                />
            </Box>
        </React.Fragment>
    );
}

export default ChiefClevel;
