export const Row = [
  {
    "id": 1,
    "supplier": "RXF",
    "zone": "Lisboa, Porto. Algarve",
    "event_type": "Large",
    "status_color": "#81d4fa",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "Europalco",
    "zone": "Lisboa, Porto. Algarve",
    "event_type": "Large",
    "status_color": "#81d4fa",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Euro logistics",
    "zone": "Lisboa, Porto. Algarve",
    "event_type": "Small",
    "status_color": "#f48fb1",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "Audi Nova",
    "zone": "Porto, Lisboa, Algarve",
    "event_type": "Small",
    "status_color": "#f48fb1",
    "ranking": "85%"
  }
]