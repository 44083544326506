import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Project",
    type: types.Text,
    name: "project",
    required: true
  },
  {
    title: "Customer",
    type: types.Text,
    name: "customer",
    required: true
  },
  {
    title: "Budget Amount ",
    type: types.Text,
    name: "budget_amount_",
    required: true,
  },
  {
    title: "Estimated Date ",
    type: types.Date,
    name: "estimated_date_",
    required: true
  },
  {
    title: "Contact",
    type: types.Text,
    name: "contact",
    required: true
  },
  {
    title: "Voqin Status",
    type: types.Chip,
    name: "voqin_status",
    required: true
  },
  {
    title: "Referral",
    type: types.Chip,
    name: "referral",
    required: true
  },
  {
    title: "Regimen",
    type: types.Text,
    name: "regimen",
    required: true
  },
  {
    title: "Client Agency",
    type: types.Text,
    name: "client_agency",
    required: true
  },
  {
    title: "Sales Office",
    type: types.Chip,
    name: "sales_office",
    required: true
  },
  {
    title: "Probability",
    type: types.Text,
    name: "probability",
    required: true
  },
  {
    title: "Voqin Points",
    type: types.Text,
    name: "voqin_points",
    required: true
  },
  {
    title: "Comments",
    type: types.Text,
    name: "comments",
    required: true
  },
];

export const FilterStatusItems = ["Travel Agency", "Management Fee", "SUCOE"] 

export const FilterTypeItems = ["Yes", "No", "To Qualify", "Qualified", "Unqualified"]