import React, {useState} from 'react'
import { Navigate} from 'react-router-dom'
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Lock from '@mui/icons-material/Lock'
import {useAppSelector, useAppDispatch} from 'app/hooks'
import {getLogin} from 'slices/auth'
import routes from 'enums/routes'
import signin from 'images/signin.svg'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Fields = styled.form`
    background-color: #f5f5f5;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
`

const Form = styled.div`
    max-width: 800px;
    max-height: 400px;
    margin: auto;
    align-self: center;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
`

const Text = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    letter-spacing: 1px;
`

const Img = styled.img`
    object-fit: cover;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
`

const input = {
    width: '100%',
    marginBottom: '30px'
}

const button = {
    width: '100%'
}

const Signin = () => {
    const [data, setData] = useState({
        login: '',
        pass: ''
    })

    const authorized = useAppSelector(state => state.auth.authorized)

    const dispatch = useAppDispatch()

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        
        dispatch(getLogin({
            login: data.login.trim(),
            pwd: data.pass.trim()
        }))
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    if (authorized) {
        return <Navigate to={`${routes.Projects}`} replace={true} />
    }

    return (
        <Container>
            <Form>
                <Fields onSubmit={onSubmit} method='post'>
                    <div style={{padding: '40px'}}>
                        <Text>Bem-vindo de volta,</Text>
                        <TextField
                            onChange={onChange}
                            name='login'
                            variant="outlined"
                            label='Login'
                            sx={input}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            onChange={onChange}
                            name='pass'
                            variant="outlined"
                            label='Password'
                            sx={input}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            type='submit'
                            sx={button}
                            onClick={onSubmit}
                        >
                            Sign in
                        </Button>
                    </div>
                </Fields>
                <Img src={signin} />
            </Form>
        </Container>
    )
}

export default Signin