const Column = {
  columns: [
{
  name: "employee",
  title: "Employee",
  width: 170,
  type: "text"
},
{
  name: "manager",
  title: "Manager",
  width: 170,
  type: "text"
},
{
  name: "role",
  title: "Role",
  width: 170,
  type: "text"
},
{
  name: "department",
  title: "Department",
  width: 170,
  type: "text"
},
{
  name: "adaptability",
  title: "Adaptability",
  width: 170,
  type: "text"
},
{
  name: "resilience",
  title: "Resilience",
  width: 170,
  type: "text"
},
{
  name: "empathy",
  title: "Empathy",
  width: 170,
  type: "text"
},
{
  name: "ownership",
  title: "Ownership",
  width: 170,
  type: "text"
},
{
  name: "collaborative",
  title: "Collaborative",
  width: 170,
  type: "text"
},
{
  name: "team_management",
  title: "Team Management\n",
  width: 170,
  type: "text"
},
{
  name: "leadership",
  title: "Leadership\n",
  width: 170,
  type: "text"
},
{
  name: "voqins_global_vision",
  title: "VOQIN's Global Vision ",
  width: 170,
  type: "text"
},
{
  name: "kpi_average",
  title: "KPI Average",
  width: 170,
  type: "text"
},
{
  name: "cultural",
  title: "Cultural",
  width: 170,
  type: "text"
},
{
  name: "business",
  title: "Business",
  width: 170,
  type: "text"
},
{
  name: "global_fit",
  title: "Global Fit",
  width: 170,
  type: "text"
},
{
  name: "completness",
  title: "Completness",
  width: 170,
  type: "text"
}
  ]
}

export default Column