export const Row = [
  {
    "id": 1,
    "default_questionnaire": "Chief C-Level",
    "last_update": "20/08/2022"
  },
  {
    "id": 2,
    "default_questionnaire": "Self C-Level",
    "last_update": "20/08/2022"
  },
  {
    "id": 3,
    "default_questionnaire": "Team Member",
    "last_update": "20/08/2022"
  }
]