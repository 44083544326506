export const Row = [
  {
    "id": 1,
    "supplier": "TBC Events",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "Joana Melo Moreira",
    "zone": "Lisboa, Portugal",
    "type_of_staff": "Guide",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Sara Pontes",
    "zone": "Lisboa, Portugal",
    "type_of_staff": "Hostess, Cordinator",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "Paula Braga ",
    "zone": "Lisboa, Portugal",
    "type_of_staff": "Hostess, Cordinator",
    "ranking": "85%"
  },
  {
    "id": 5,
    "supplier": "Designer de PPT (event CTT)",
    "zone": "Lisboa, Portugal",
    "type_of_staff": "Designer",
    "ranking": "84%"
  },
  {
    "id": 6,
    "supplier": "Ludmila Ferreira",
    "zone": "Lisboa, Portugal",
    "type_of_staff": "Guide",
    "ranking": "83%"
  },
  {
    "id": 7,
    "supplier": "Mónica Nogueira",
    "zone": "Porto, Portugal",
    "ranking": "82%"
  }
]