import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';
import EditForm from 'components/EditForm';
import { Row } from './row';
import Column from './column';
import { formConfig } from './formConfig';
import { useNavigate } from 'react-router-dom';

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const VoqinStructure = () => {
    const [checked, setCheck] = useState<number[]>([]);
    const handleSelect = (rowIds: number[]) => setCheck(rowIds);
    const [formOpen, setFormOpen] = useState<boolean>(false);

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const selectedRow = selectedRowIndex !== -1 ? Row[selectedRowIndex] : null;

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormConfirm = () => {
      handleFormClose()
    }

    const navigate = useNavigate();

    const initialValues = useMemo(() => {
        if (selectedRow) {
            return {
                employee: selectedRow.employee || '',
                manager: selectedRow.manager || '',
                role: selectedRow.role || '',
                department: selectedRow.department || '',
                complete: selectedRow.complete || '',
            };
        }
        return {
            employee: '',
            manager: '',
            role: '',
            department: '',
            complete: '',
        };
    }, [selectedRowIndex]);

    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
                <Breadcrumbs texts={['Current Voqin Structure for Evaluation' ]} />
            </Box>
 
            <Box style={{ height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex' }}>
               
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={Row}
                    columns={Column.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                />

                <EditForm
                    open={formOpen}
                    handleClose={handleFormClose}
                    handleConfirm={handleFormConfirm}
                    title={'Edit Voqin Way'}
                    config={formConfig}
                    initialValues={initialValues}
                />
            </Box>
        </React.Fragment>
    );
}

export default VoqinStructure;