import Card from 'components/Card';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Tooltip} from '@mui/material';
import React, { useState} from 'react';
import Breadcrumbs from 'components/Breadcrumbs'
import EditForm from 'components/EditForm';
import { overview } from './data/overview';
import routes from 'enums/routes';
import { useNavigate } from 'react-router-dom';
import { formConfig } from './data/config';
import { Add } from '@mui/icons-material';

const TOPBAR_HEIGHT = 64

const config1 = {
    id: 'id',
    title: 'meeting_template',
    fields: [
    ],
};
const config2 = {
    id: 'id',
    title: 'congress_template',
    fields: [
    ]
}

const Templates = () => {
  const project = overview[0];
  const project2 = overview[1];

  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [addTemplate, setAddTemplate] = useState<boolean>(false)
  const navigate = useNavigate();

  const handleAddTemplate = () => {
    setAddTemplate(true)
  }
  
  const handleCloseTemplate = () => {
    setAddTemplate(false)
  }

  const handleConfirmTemplate = () => {
    handleCloseTemplate()
  }

  const handleFormOpen = () => {
      setOpenForm(true)
  }

  const handleFormClose = () => {
    setOpenForm(false)
}

const handleConfirm = () => {
    handleFormClose()
}

const onClick = () => {
    navigate(`${routes.Template}/1/${routes.OverviewTemplate}`)
}


const handlePopupClose = () => {
    setOpenPopup(false)
}

  return (
    <React.Fragment>
        <Box style ={{display: 'flex', height: TOPBAR_HEIGHT}}>
          <Breadcrumbs texts={['Default Projects', 'Overview']}/>
            <Box style={{margin:'18px', marginLeft: 'auto'}}>
            <Tooltip title="Create Template" placement="top">
                    <Fab onClick={() => handleAddTemplate()} color="secondary" size='small' sx={{marginLeft: '20px'}}>
                        <Add />
                    </Fab>
                </Tooltip>
            </Box>
            </Box>
                         {project ? (
       <div> 
        <Card config={config1} data={project}onClick={onClick}presentation />
        
        </div>
      ) : (
        <div>No project data available.</div>
      )}
                               {project ? (
       <div> 
        <Card config={config2} data={project2} onClick={handleFormOpen}presentation />
        </div>
      ) : (
        <div>No project data available.</div>
      )}
 <EditForm
              open={openForm}
              handleClose={handleFormClose}
              handleConfirm={handleConfirm}
              title={'Change project'}
              config={formConfig}
              initialValues={{}}         
              />

<Dialog
                open={addTemplate}
                onClose={handleCloseTemplate}
            >
                <DialogTitle>
                    Confirm your action
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create a new Template?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTemplate}>Cancel</Button>
                    <Button onClick={handleConfirmTemplate} variant='contained'>Confirm</Button>
                </DialogActions>
            </Dialog>
       
    </React.Fragment>
  );
};

export default Templates;
