export const Row = [
  {
    "id": 1,
    "supplier": "Festas & Sonhos",
    "zone": "Lisboa,Algarve, Comporta, Algarve",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "Tudo é festa",
    "zone": "Lisboa,Algarve, Comporta, Algarve",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Festa Aluga",
    "zone": "Lisboa,Algarve, Comporta, Algarve",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "Florista no Algarve",
    "zone": "Algarve",
    "ranking": "85%"
  },
  {
    "id": 5,
    "supplier": "Florista do Porto",
    "zone": "Porto",
    "ranking": "84%"
  }
]