export const Row = [
  {
    "id": 1,
    "employee": "Robert, Palmer",
    "role": "Sales Director",
    "department": "Sales",
    "questionnaires_published": "2/4"
  },
  {
    "id": 2,
    "employee": "Randy, Heather",
    "role": "Sales Specialist",
    "department": "Sales",
    "questionnaires_published": "3/4"
  },
  {
    "id": 3,
    "employee": "Manuel Castro",
    "role": "Team Member",
    "department": "Sales",
    "questionnaires_published": "2/3"
  }
]