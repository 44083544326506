export const Row = [
  {
    "id": 1,
    "supplier": "Food Story",
    "zone": "Lisboa,Porto,Algarve",
    "max_capacity": 1000,
    "voqin_stars": 4,
    "ranking":"90%"
  },
  {
    "id": 2,
    "supplier": "Penha Longa",
    "zone": "Lisboa,Porto,Algarve",
    "max_capacity": 1000,
    "furniture": "Yes",
    "voqin_stars": 5,
    "ranking":"89%"
  },
  {
    "id": 3,
    "supplier": "Casa do Marquês",
    "zone": "Lisboa,Porto,Algarve",
    "max_capacity": 1000,
    "furniture": "Yes",
    "voqin_stars": 4,
    "ranking":"85%"
  },
  {
    "id": 4,
    "supplier": "Palace Catering",
    "zone": "Porto,Lisboa,Algarve",
    "max_capacity": 1000,
    "furniture": "Yes",
    "voqin_stars": 4,
    "ranking":"85%"
  },
  {
    "id": 5,
    "supplier": "Chefe com Alma",
    "zone": "Lisboa",
    "max_capacity": 50,
    "furniture": "No",
    "voqin_stars": 4,
    "ranking":"83%"
  }
]