export const Row = [
  {
    "id": 1,
    "question_code": "C1",
    "header": "Adaptability",
    "question": "Ability to quickly adapt to a new context and flexibility to let go of the past and face a new reality, changing the course if necessary. \nAlert; observant and agile to new ideas.",
    "order": 1,
    "type": "Cultural",
    "status_color": "#81d4fa",
  },
  {
    "id": 2,
    "question_code": "C3",
    "header": "Resilience",
    "question": "Knowing how to deal with the battlefronts, be able to get out of it, and become motivated. Doesn't rest until solves one subject, goes until the end, and becomes an example for other people.",
    "order": 2,
    "type": "Cultural",
    "status_color": "#81d4fa",
  },
  {
    "id": 3,
    "question_code": "C2",
    "header": "Empathy",
    "question": "Ability to pay attention and put yourself in the other's shoes and see their perspective and/ or feel their emotions.  Use of empathic communication; anticipate and offer solutions or take action to assist.",
    "order": 3,
    "type": "Cultural",
    "status_color": "#81d4fa",
  },

  {
    "id": 4,
    "question_code": "C4",
    "header": "Ownership",
    "question": "Entrepreneurial stance, engaged, proactive and willing to take risks. Fulfills deadlines and checks the quality of delivery of demands. Admits errors, values rectifications and aims to simplify problems and turn them into solutions." ,
    "order": 4,
    "type": "Cultural",
    "status_color": "#81d4fa",
  }
  ,
  {
    "id": 5,
    "question_code": "C5",
    "header": "Collaborative",
    "question": "Understanding that there are no heroes. That it is only possible to be productive to go far when working as a team. Behavior, posture, and emotional intelligence in internal and external work relations.",
    "order": 5,
    "type": "Cultural",
    "status_color": "#81d4fa",
  }
  ,
  {
    "id": 6,
    "question_code": "C6",
    "header": "Team Management",
    "question": "Approachability –team members find the team leader easy to meet or deal with. / Conflict Management – Reduces the negative aspects of conflict while increasing the positive aspects focusing to improve learning and group outcomes ( including effectiveness or performance. / Mentoring – Constantly mentors team members and also mentors new team members during their onboarding phase. / Performance Coaching - coaches employees in their job roles so that they increase their productivity, performance, and output.",
    "order": 6,
    "type": "Cultural",
    "status_color": "#81d4fa",
  }
  ,
  {
    "id": 7,
    "question_code": "C7",
    "header": "Leadership",
    "question":"Clarity of thought and communication about mission and strategy. / Personal Integrity - Stands for something meaningful beyond oneself rather than being motivated by narrow personal interests. Avoids behavior like favoritism, conflicts of interest, inappropriate language, inappropriate work relationships, etc., that erode trust. / Generates solutions to problems that do not depend on “business as usual” experience and thinking. /Leads by example.",
    "order": 7,
    "type": "Cultural",
    "status_color": "#81d4fa",
  }
  ,
  {
    "id": 8,
    "question_code": "C8",
    "header": "VOQIN'S Global Vision",
    "question": "Understanding and knowledge of the company’s vision; activity areas and major indicators. High level of involvement with the cultural values of the company.",
    "order": 8,
    "type": "Cultural",
    "status_color": "#81d4fa",
  },
  {
    "id": 9,
    "question_code": "KPI1",
    "header": "VOQIN'S Global Vision",
    "question": "Response time to internal stakeholders and line manager: up to 3 working hours.",
    "order": 9,
    "type": "Business",
    "status_color": "#f48fb1",
  }
]