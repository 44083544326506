import {types} from 'enums/fields'

export const Column = [
{
  name: "employee",
  title: "Employee",
  width: 170,
  type: types.Text
},
{
  name: "manager",
  title: "Manager",
  width: 170,
  type: types.Text
},
{
  name: "role",
  title: "Role",
  width: 170,
  type: types.Text
},
{
  name: "department",
  title: "Department",
  width: 170,
  type: types.Text
},
{
  name: "start_date",
  title: "Start date",
  width: 170,
  type: types.Date
},
{
  name: "level",
  title: "Level",
  width: 170,
  type: types.Text
}
]


