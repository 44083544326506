import React, { useMemo, useState } from 'react';
import { Box, Chip, Fab, Tooltip } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';


import EditForm from 'components/EditForm';

import { Chart as ChartJS, ArcElement, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TaskAlt } from '@mui/icons-material';
import { Row } from './row';
import { formConfig } from './formConfig';
import Column from './column';
import routes from 'enums/routes';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Legend, BarElement, CategoryScale, LinearScale)

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const icon = {
    fontSize: 18
}

const chip = {
    marginLeft: 2,
    cursor: 'pointer',
    width: '150px'
}



const PastEvaluations = () => {
    const [checked, setCheck] = useState<number[]>([]);
    const handleSelect = (rowIds: number[]) => setCheck(rowIds);
    const [addTemplate, setAddTemplate] = useState<boolean>(false)

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;
    const selectedRow = selectedRowIndex !== -1 ? Row[selectedRowIndex] : null;

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormConfirm = () => {
      handleFormClose()
    }

    const handleAddTemplate = () => {
        setAddTemplate(true)
      }

      const handleFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const navigate = useNavigate();
    const onClick = () => {
        navigate(`${routes.Voqinway}/${routes.Results}/${routes.PastEvaluations}/${routes.PastEvaluationsPeriods}`)
      }

  

    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
                <Breadcrumbs texts={['Voqin Way', 'Past Evaluations']} />
            </Box>
            <Box style={{margin:'18px', marginTop: 'auto', marginLeft:'auto'}}>
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={Row}
                    columns={Column.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                    onRowClick={onClick}
                />

                <EditForm
                    open={formOpen}
                    handleClose={handleFormClose}
                    handleConfirm={handleFormConfirm}
                    title={'Edit Voqin Way'}
                    config={formConfig}
                    initialValues={{}}
                />
            </Box>
        </React.Fragment>
    );
}

export default PastEvaluations;
