import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Supplier",
    type: types.Text,
    name: "supplier",
    required: true
  },
  {
    title: "Zone",
    type: types.Text,
    name: "zone",
    required: true
  },
  {
    title: "Sub-Zone",
    type: types.Text,
    name: "subzone",
    required: true
  },
  {
    title: "Capacity",
    type: types.Text,
    name: "capacity",
    required: true
  },
  {
    title: "Exclusive Catering",
    type: types.Text,
    name: "exclusive_catering",
    required: true
  },
  {
    title: "Exclusive AudioVisuals",
    type: types.Text,
    name: "exclusive_audiovisuals",
    required: true
  },
  {
    title: "Venue Type",
    type: types.Chip,
    name: "venue_type",
    required: true
  },
  {
    title: "Ranking",
    type: types.Text,
    name: "ranking",
    required: true
  }
];
