export const Row = [
  {
    "id": 1,
    "category": "Accomodation",
    "criteria1": "Price/Quality",
    "weight1": "30%",
    "criteria2": "Premises Presentation",
    "weight2": "20%",
    "criteria3": "Reception Desk",
    "weight3": "10%",
    "criteria4": "Rappel",
    "weight4": "20%",
    "criteria5": "Referral",
    "weight5": "20%",
    "total": "100%"
  },
  {
    "id": 2,
    "category": "F&B - Catering",
    "criteria1": "Price",
    "weight1": "30%",
    "criteria2": "Quality",
    "weight2": "10%",
    "criteria3": "Contract",
    "weight3": "15%",
    "criteria4": "Promptness",
    "weight4": "45%",
    "total": "100%"
  },
  {
    "id": 3,
    "category": "F&B - Restaurants",
    "criteria1": "Food Quality",
    "weight1": "20%",
    "criteria2": "Overall Service",
    "weight2": "10%",
    "criteria3": "Cleanliness",
    "weight3": "15%",
    "criteria4": "Order accuracy",
    "weight4": "15%",
    "criteria5": "Seed of Service",
    "weight5": "20%",
    "criteria6": "Value",
    "weight6": "10%",
    "criteria7": "Overall Experience",
    "weight7": "10%",
    "criteria8": "",
    "weight8":"",
     "total": "100%",
  },
  {
    "id": 4,
    "category": "Transportation",
    "criteria1": "Promptness",
    "weight1": "10%",
    "criteria2": "Safety",
    "weight2": "20%",
    "criteria3": "Cleanliness",
    "weight3": "20%",
    "criteria4": "Staff",
    "weight4": "20%",
    "criteria5": "Price",
    "weight5": "30%",
    "total": "100%"
  },
  {
    "id": 5,
    "category": "Activities",
    "criteria1": "Safety",
    "weight1": "15%",
    "criteria2": "Price",
    "weight2": "25%",
    "criteria3": "Staff",
    "weight3": "20%",
    "criteria4": "Price",
    "weight4": "40%",
    "total": "100%"
  },
  {
    "id": 6,
    "category": "Venues",
    "criteria1": "Location",
    "weight1": "10%",
    "criteria2": "Reception",
    "weight2": "20%",
    "criteria3": "F&B Minimum",
    "weight3": "20%",
    "criteria4": "Audio Visual",
    "weight4": "20%",
    "criteria5": "Rappel",
    "weight5": "30%",
    "total": "100%"
  },
  {
    "id": 7,
    "category": "Decor",
    "criteria1": "Attention to detail",
    "weight1": "30%",
    "criteria2": "Promptness",
    "weight2": "30%",
    "criteria3": "Quality",
    "weight3": "40%",
    "total": "100%"
  },
  {
    "id": 8,
    "category": "Audio Visual",
    "criteria1": "Organization stability",
    "weight1": "20%",
    "criteria2": "References",
    "weight2": "20%",
    "criteria3": "Project Management",
    "weight3": "10%",
    "criteria4": "Equipment quality",
    "weight4": "20%",
    "criteria5": "Price",
    "weight5": "30%",
    "total": "100%"
  },
  {
    "id": 9,
    "category": "Entertainment",
    "criteria1": "Safety",
    "weight1": "15%",
    "criteria2": "Price",
    "weight2": "25%",
    "criteria3": "Staff",
    "weight3": "20%",
    "criteria4": "Price",
    "weight4": "40%",
    "total": "100%"
  },
  {
    "id": 10,
    "category": "Gifts",
    "criteria1": "Attention to detail",
    "weight1": "30%",
    "criteria2": "Promptness",
    "weight2": "30%",
    "criteria3": "Quality",
    "weight3": "40%",
    "total": "100%"
  },
  {
    "id": 11,
    "category": "Staff",
    "criteria1": "Presentation",
    "weight1": "20%",
    "criteria2": "Punctuality",
    "weight2": "20%",
    "criteria3": "Price",
    "weight3": "20%",
    "criteria4": "Language skills",
    "weight4": "20%",
    "criteria5": "Coordination skills",
    "weight5": "20%",
    "total": "100%"
  },
  {
    "id": 12,
    "category": "Production",
    "criteria1": "Attention to detail",
    "weight1": "30%",
    "criteria2": "Promptness",
    "weight2": "30%",
    "criteria3": "Quality",
    "weight3": "40%",
    "total": "100%"
  },
  {
    "id": 13,
    "category": "Others",
    "criteria1": "Price",
    "weight1": "100%",
    "total": "100%"
  }
]