export const Row = [
  {
    "id": 1,
    "supplier": "Cantinho do Avillez",
    "zone": "Lisboa",
    "subzone": "Belém",
    "max_capacity": 50,
    "allows_exclusivity": "Yes",
    "private_room": "No",
    "volante": "No",
    "cuisine_type": "Portuguese",
    "price_level": "Moderate",
    "status_color": "#81d4fa",
    "ranking":"89%"
  },
  {
    "id": 2,
    "supplier": "Bairro do Avillez",
    "zone": "Lisboa",
    "subzone": "Chiado",
    "max_capacity": 100,
    "allows_exclusivity": "Yes",
    "private_room": "No",
    "volante": "No",
    "cuisine_type": "Portuguese",
    "price_level": "Moderate",
    "status_color": "#81d4fa",
    "ranking":"85%"
  },
  {
    "id": 3,
    "supplier": "Monte Mar",
    "zone": "Lisboa",
    "subzone": "Cais Sodré",
    "max_capacity": 200,
    "allows_exclusivity": "Yes",
    "private_room": "Yes",
    "volante": "Yes",
    "cuisine_type": "SeeFood, Portuguese",
    "price_level": "High",
    "status_color": "#f48fb1",
    "ranking":"85%"
  },
  {
    "id": 4,
    "supplier": "Zambeze",
    "zone": "Lisboa",
    "subzone": "Castelo",
    "max_capacity": 100,
    "allows_exclusivity": "Yes",
    "private_room": "No",
    "volante": "No",
    "cuisine_type": "African, Internacional",
    "price_level": "Moderate",
    "status_color": "#81d4fa",
    "ranking":"83%"
  },
  {
    "id": 5,
    "supplier": "Sintra Central",
    "zone": "Sintra",
    "max_capacity": 200,
    "allows_exclusivity": "Yes",
    "private_room": "Yes",
    "volante": "Yes",
    "cuisine_type": "Portuguese, Internacional",
    "price_level": "Moderate",
    "status_color": "#81d4fa",
    "ranking":"82%"
  },
  {
    "id": 6,
    "supplier": "Praia no Parque",
    "zone": "Lisboa",
    "subzone": "Parque",
    "max_capacity": 200,
    "allows_exclusivity": "Yes",
    "private_room": "No",
    "volante": "Yes",
    "cuisine_type": "Internacional",
    "price_level": "High",
    "status_color": "#f48fb1",
    "ranking":"82%"
  },
  {
    "id": 7,
    "supplier": "100 Maneiras",
    "zone": "Lisboa",
    "subzone": "Chiado",
    "max_capacity": 50,
    "allows_exclusivity": "Yes",
    "private_room": "Yes",
    "volante": "No",
    "cuisine_type": "Internacional",
    "price_level": "High",
    "status_color": "#f48fb1",
    "ranking":"82%"
  },
  {
    "id": 8,
    "supplier": "Belcanto",
    "zone": "Lisboa",
    "subzone": "Chiado",
    "max_capacity": 80,
    "allows_exclusivity": "No",
    "private_room": "No",
    "volante": "No",
    "michelin_stars": 2,
    "cuisine_type": "Internacional",
    "price_level": "High",
    "status_color": "#f48fb1",
    "ranking":"82%"
  }
]