import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { renderMoney, renderPercent } from "oldcomponents/utils";
import { Spreadsheet } from "@framelink/spreadsheet_ts";
import FolderList from "./SuppliersList";
import SuppliersList from "./SuppliersList";
import { useSearchParams } from "react-router-dom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IPropsModalProfitLossItem {
    open: boolean
    onClose: Function
    accepted: boolean
    // info: any,
    // summary: any,
    // kpi: any,
    // tab: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            { value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            ) }
        </div>
    )
}

const InfoItem = (props: { name: string, value: any, color?: any }) => {
    return (
        <Box>
            <h5 style={{ marginBottom: 5, color: props.color }}>{props.name}</h5>
            {props.value}
        </Box>
    )
}

const KpiItem = (props: { name: string, value: any, color?: any, tooltip?: string }) => {
    return (
        <Box>
            <h5 style={{ marginBottom: 5 }}>{props.name}</h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ display: 'inline-block', width: 110 }}>{props.value}</span>
                {props.tooltip && (<Tooltip title={props.tooltip}>
                    <InfoIcon color="primary" style={{ marginLeft: 10, color: props.color }} />
                </Tooltip>)}
            </div>
        </Box>
    )
}

const SummaryItem = (props: { name: string, value: any }) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
            <h5 style={{ margin: 0, marginBottom: 5 }}>{props.name}</h5>
            <h3 style={{ margin: 0, marginLeft: 'auto'}}>{props.value}</h3>
        </Box>
    )
}

const SummarySubItem = (props: { name: string, value: any }) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
            <div style={{ paddingLeft: 20, fontSize: '0.83em', margin: 0, marginBottom: 5 }}>{props.name}</div>
            <h5 style={{ margin: 0, marginLeft: 'auto'}}>{props.value}</h5>
        </Box>
    )
}

export default function ModalProfitLossItem(props: IPropsModalProfitLossItem) {
    const theme: any = useTheme() // todo - replace any

    const [open, setOpen] = useState<boolean>(props.open)

    const [searchParams] = useSearchParams();

    const [checked, setCheck] = useState<number[]>([])

    const VoqinColorSuccess = theme?.palette?.success?.main || ''
    const VoqinColorError = theme?.palette?.error?.main || ''

    useEffect(() => setOpen(props.open), [props.open])

    const onClose = (): void => props.onClose()

    const getTabProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const marginBottom = 1.8

    const [values, setValues] = useState({
        unit: '1',
        participants: '1',
        buyingcost: '42.00',
        profit: '30.67%',
        commisionsfromsuppliers: '10%',
        vat: '23%',
        grossunitcost: '78.00',
        total: '78.00',
        totalwtaxes: '78.00',
        profitcalc: '44.86',
        profitmargin: '40.00%'
    })

    const acceptedValues = {
        unit: '1',
        participants: '1',
        buyingcost: '38',
        profit: '40.00%',
        commisionsfromsuppliers: '0%',
        vat: '23%'
    }

    const GridSize = 4

    const clientAccept = 'Accepted by client'

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle sx={{ padding: 4 }}>
                    {/* <Typography variant="h2">My Dialog Title</Typography> */}
                    Mercedes E-Class with professional driver at disposal
                </DialogTitle>
                <DialogContent sx={{ padding: 4 }}>
                    <Grid container spacing={2} sx={{ padding: 1 }} className="profit-loss-item">
                        <Grid item xs={GridSize}>
                            <TextField label="Unit" value={values.unit} helperText={!props.accepted ? null : `${clientAccept}: ${acceptedValues.unit}`} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="Participants" value={values.participants} helperText={!props.accepted ? null : `${clientAccept}: ${acceptedValues.participants}`} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="Buying cost" value={values.buyingcost} helperText={!props.accepted ? null : `${clientAccept}: ${acceptedValues.buyingcost}`} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="Profit" value={values.profit} helperText={!props.accepted ? null : `${clientAccept}: ${acceptedValues.profit}`} sx={{ width: '100%' }} inputProps={{ readOnly: props.accepted }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="Commisions from suppliers" value={values.commisionsfromsuppliers} helperText={!props.accepted ? null : `${clientAccept}: ${acceptedValues.commisionsfromsuppliers}`} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="VAT" value={values.vat} helperText={!props.accepted ? null : `${clientAccept}: ${acceptedValues.vat}`} sx={{ width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: 2, padding: 1 }} className="profit-loss-item">
                        <Grid item xs={GridSize}>
                            <TextField label="Gross unit cost" value={values.grossunitcost} inputProps={{ readOnly: true }} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="Total" value={values.total} inputProps={{ readOnly: true }} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="Total w/ taxes" value={values.totalwtaxes} inputProps={{ readOnly: true }} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="Profit" value={values.profitcalc} inputProps={{ readOnly: true }} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={GridSize}>
                            <TextField label="% Profit - margin" value={values.profitmargin} inputProps={{ readOnly: true }} sx={{ width: '100%' }} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: 4 }}>
                    <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onClose} variant='contained'>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
