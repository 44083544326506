export const Row = [
  {
    "id": 1,
    "supplier": "Amiroad",
    "zone": "Lisboa, Algarve, Porto",
    "type": "Car, MiniVan,MiniBus,Autobus",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "Estremadura",
    "zone": "Lisboa, Algarve, Porto",
    "type": "MiniBus,Autobus",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "AneTours",
    "zone": "Porto, Lisboa, Algarve",
    "type": "Car, MiniVan,MiniBus,Autobus",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "VIP RC Transports",
    "zone": "Madeira",
    "type": "Car, MiniVan,MiniBus,Autobus",
    "ranking": "85%"
  },
  {
    "id": 5,
    "supplier": "VipChaufers",
    "zone": "Algarve, Lisboa, Porto",
    "type": "Car, MiniVan,MiniBus",
    "ranking": "85%"
  }
]