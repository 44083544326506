const Column = {
  columns: [
{
  name: "evaluation_period",
  title: "Evaluation Period",
  width: 170,
  type: "text"
},
  ]
}

export default Column