/*import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Chip from '@mui/material/Chip'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import FilterAlt from '@mui/icons-material/FilterAlt'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getEmployees, deleteEmployee, createEmployee, updateEmployee} from 'slices/employee'
import FilterForm from 'components/FilterForm'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import { Column } from './column'
import { Row } from './row'
import { formConfig } from './formConfig'

export const TopHeight = 60
export const BottomHeight = 60

const Bottom = styled.div<{height: number}>`
    height: ${props => props.height}px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    justify-content: flex-end;
`

const Top = styled.div`
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    justify-content: space-between;
`

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
`

const chip = {
    width: '180px',
    padding: '0 10px',
    marginLeft: 'auto',

    '.MuiChip-icon': {
        color: 'inherit',
        fontSize: 18
    }
}

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string,
    color?: string
}

const Employee = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [employeeId, setEmployeeId] = useState<number | null>(null)
    const [filters, setFilters] = useState<{employee: number[]}>({employee: []})
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const editFormTitle = useRef<string>('')

    const employees = useAppSelector(state => state.employee.employees)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getEmployees())
    }, [dispatch])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            documentId={data.id}
            color={column.color}
            type={column.type}
            value={value}
            columnId={column.name}
            title={column.title}
            onChange={() => {}}
            itemId={column.itemId}
            itemLabel={column.itemLabel}
            itemColor={column.itemColor}
        />
    }

    const handleOpen = (documentId?: number) => {
        if (documentId) {
            setEmployeeId(documentId)
            editFormTitle.current = 'Change Employee data'
        } else {
            editFormTitle.current = 'Create new Employee'
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setEmployeeId(null)
    }

    const handleDelete = () => {
        dispatch(deleteEmployee({ids: checked}))
        setCheck([]) // !!!
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}

        if (employeeId) {
            obj.id = employeeId

            dispatch(updateEmployee({data: [{...obj}]}))
            setEmployeeId(null)
        } else {
            dispatch(createEmployee({data: [{...obj}]}))
        }

        handleClose()
    }

    const handleFilterOpen = () => {
        setOpenFilter(true)
    }

    const handleFilterClose = () => {
        setOpenFilter(false)
    }

    const handleFilterConfirm = (value: any) => {
        setFilters({
            ...filters,
            employee: value
        })

        handleFilterClose()
    }

    const initialValues = useMemo(() => {
        const employee = employees.find(item => item.id === employeeId)
    
        return {
            id: employee ? employee.id : '',
            name: employee ? employee.name : '',
            manager: employee && employee.manager ? employee.manager : '',
            role: employee ? employee.role : '',
            department: employee && employee.department ? employee.department : '',
            start_date: employee ? employee.start_date : '',
            level: employee ? employee.level : '',
        }
    }, [employees, employeeId])
    

    return (
        <React.Fragment>
            <Top>
                <Chip
                    icon={<FilterAlt />}
                    label={'Filters'}
                    sx={chip}
                    onClick={handleFilterOpen}
                    variant={filters.employee.length ? 'filled' : 'outlined'}
                    color='secondary'
                />
            </Top>
            <Container margin={BottomHeight + TopHeight}>
                <Spreadsheet
                    columns={Column}
                    rows={Row}
                    order={[]}
                    fixedCellWidth={100}
                    heightRow={60}
                    editableHeader={false}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    propId='id'
                    onRowClick={handleOpen}
                />
            </Container>
            <Bottom height={BottomHeight}>
                {!!checked.length &&
                    <Tooltip title="Delete selected records" placement="top">
                        <Fab onClick={handleDelete} color="error" size='small'>
                            <Delete />
                        </Fab>
                    </Tooltip>
                }
                <Tooltip title="Create record" placement="top">
                    <Fab onClick={() => handleOpen()} color="secondary" size='small' sx={{marginLeft: '20px'}}>
                        <Add />
                    </Fab>
                </Tooltip>
            </Bottom>
            <EditForm
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={editFormTitle.current}
                config={formConfig}
                initialValues={initialValues}
            />
            <FilterForm
                open={openFilter}
                handleClose={handleFilterClose}
                handleConfirm={handleFilterConfirm}
                value={filters.employee} 
                options={employees}               
            />
        </React.Fragment>
    )
}

export default Employee*/

import React, { useMemo, useState } from 'react'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import { AutoFixHigh } from '@mui/icons-material'
import EditForm from 'components/EditForm'
import { formConfig, FilterStatusItems, FilterTypeItems } from 'containers/TeamMembers/formConfig'
import { Row } from './row'
import { Column } from './column'


const icon = {
    fontSize: 18
}

const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78

const TeamMembers = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [checked, setCheck] = useState<number[]>([])
    const handleSelect = (rowIds: number[]) => setCheck(rowIds)
    const [openStatusFilter, setOpenStatusFilter] = useState<HTMLDivElement>()
    const [openTypeFilter, setOpenTypeFilter] = useState<HTMLDivElement>()
    const handleStatusFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => setOpenStatusFilter(e.currentTarget)
    const handleTypefilterOpen = (e: React.MouseEvent<HTMLDivElement>) => setOpenTypeFilter(e.currentTarget)
    const handleStatusFilterClose = () => setOpenStatusFilter(undefined)
    const handleTypeFilterClose = () => setOpenTypeFilter(undefined)
    const filterStatusItems = (value: string) => handleStatusFilterClose()
    const filterTypeItems = (value: string) => handleTypeFilterClose()

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;

    const handleFormOpen = (rowIndex: number) => {
        if(rowIndex >= 0){
        const oneIndexedRowIndex = convertTo1Indexed(rowIndex);
        setSelectedRowIndex(oneIndexedRowIndex);
        setOpenForm(true);
        }
      };
  
    const handleFormClose = () => {
      setOpenForm(false)
    }
  
    const handleConfirm = () => {
      handleFormClose()
    }
    
    const selectedRow = selectedRowIndex !== null ? Row[selectedRowIndex] : null;

    const initialValues = useMemo(() => {
      if (selectedRow) {
        return {
          employee: selectedRow.employee || '',
          manager: selectedRow.manager || '',
          role: selectedRow.role || '',
          department: selectedRow.department || '',
          start_date: selectedRow.start_date || null,
          level: selectedRow.level || null,
        };
      }
      return {
        employee: '',
        manager: '',
        role: '',
        department: '',
        start_date: null,
        level: null,
      };
    }, [selectedRowIndex]);

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['TeamMembers']} />
                    <Box style={{margin: '18px', marginLeft: 'auto'}}>
                        <Chip
                            icon={<TaskAlt sx={icon} />}
                            label={'Manager'}
                            sx={{
                                cursor: 'pointer',
                                width: '150px'
                            }}
                            onClick={handleStatusFilterOpen}
                        />
                        <Chip 
                            icon={<AutoFixHigh sx={icon} />}
                            label={'Department'}
                            sx={{
                                cursor:'pointer',
                                width:'150px'
                            }}
                            onClick={handleTypefilterOpen}
                        />

                    <Menu
                        anchorEl={openStatusFilter}
                        open={!!openStatusFilter}
                        onClose={handleStatusFilterClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    marginTop: '10px',
                                    width: '150px'
                                }
                            }
                        }}
                    >
                        {Object.values(FilterStatusItems).map(item => (
                        <MenuItem onClick={() => filterStatusItems(item)}
                            key={item}
                            value={item}
                        >
                        {item}
                        </MenuItem>
                        ))}
         
                    </Menu>
                    <Menu
                        anchorEl={openTypeFilter}
                        open={!!openTypeFilter}
                        onClose={handleTypeFilterClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    marginTop: '10px',
                                    width: '150px'
                                }
                            }
                        }}
                    >
                        {Object.values(FilterTypeItems).map(item => (
                        <MenuItem onClick={() => filterTypeItems(item)}
                            key={item}
                            value={item}
                            >
                        {item}
                        </MenuItem>
                        ))}
                    </Menu>
                    
                    </Box>
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
            <Spreadsheet
                order={[]}
                editableHeader={false}
                fixedCellWidth={100}
                checkedRows={checked}
                propId='id'
                rows={Row}
                columns={Column}
                heightRow={50}
                onCheck={handleSelect}
                renderCell={renderCell}
                onRowClick={(rowId) => handleFormOpen(rowId)}
            />
            <EditForm
                open={openForm}
                handleClose={handleFormClose}
                handleConfirm={handleConfirm}
                title={'Change Team Member'}
                config={formConfig} 
                initialValues={initialValues}                      
            />
            </Box>
        </React.Fragment>
    )
}

export default TeamMembers
