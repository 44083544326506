import React, { useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Tooltip} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';
import EditForm from 'components/EditForm';
import { useNavigate } from 'react-router-dom';

import { Row } from './row';
import Column from './column';
import { formConfig } from './formConfig';
import { Add, Upload } from '@mui/icons-material';

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const QuestionnairesToBePublished = () => {
    const [checked, setCheck] = useState<number[]>([]);
    const handleSelect = (rowIds: number[]) => setCheck(rowIds);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const selectedRow = selectedRowIndex !== -1 ? Row[selectedRowIndex] : null;
    const [addTemplate, setAddTemplate] = useState<boolean>(false)

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormConfirm = () => {
      handleFormClose()
    }

    const initialValues = useMemo(() => {
        if (selectedRow) {
            return {
                question_code: selectedRow.question_code || '',
                header: selectedRow.header || '',
                question: selectedRow.question || '',
                order: selectedRow.order || ''
            };
        }
        return {
            question_code: '',
            header: '',
            question: '',
            order: ''
        };
    }, [selectedRowIndex]);

    const navigate = useNavigate();

    const handleAddTemplate = () => {
        setAddTemplate(true)
      }
      
      const handleCloseTemplate = () => {
        setAddTemplate(false)
      }
    
      const handleConfirmTemplate = () => {
        handleCloseTemplate()
      }

    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
                <Breadcrumbs texts={['Randy Heather', 'My Questionaire']} />
            </Box>
            <Box style={{margin:'18px', marginLeft: 'auto'}}>
            <Tooltip title="Create From Default" placement="top">
                    <Fab onClick={() => handleAddTemplate()} color="secondary" size='small' sx={{marginLeft: '20px'}}>
                        <Add />
                    </Fab>
                </Tooltip>
                <Tooltip title="Upload From Existing" placement="top">
                    <Fab onClick={() => handleAddTemplate()} color="secondary" size='small' sx={{marginLeft: '20px'}}>
                        <Upload />
                    </Fab>
                </Tooltip>
            </Box>
 
            <Box style={{ height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex' }}>
               
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={Row}
                    columns={Column.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                />

                <EditForm
                    open={formOpen}
                    handleClose={handleFormClose}
                    handleConfirm={handleFormConfirm}
                    title={'Edit Voqin Way'}
                    config={formConfig}
                    initialValues={initialValues}
                />
                <Dialog
                open={addTemplate}
                onClose={handleCloseTemplate}
            >
                <DialogTitle>
                    Confirm your action
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create a new Questionaire?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTemplate}>Cancel</Button>
                    <Button onClick={handleConfirmTemplate} variant='contained'>Confirm</Button>
                </DialogActions>
            </Dialog>
            </Box>
        </React.Fragment>
    );
}

export default QuestionnairesToBePublished;
