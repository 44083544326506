import React, { useMemo, useState } from 'react';
import { Box, Chip, Menu, MenuItem } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';
import EditForm from 'components/EditForm';
import { useNavigate } from 'react-router-dom';
import routes from 'enums/routes';
import { Row } from './row';
import Column from './column';
import { formConfig } from './formConfig';
import { TaskAlt } from '@mui/icons-material';

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const icon = {
    fontSize: 18
}

const chip = {
    marginLeft: 2,
    cursor: 'pointer',
    width: '150px'
}

const statuses = {
    self: {value: 'Self'},
    directReport: {value: 'Direct Reports'},
    reportPeers: {value: 'Peers'},
}

const QuestionnairesPublished = () => {
    const [checked, setCheck] = useState<number[]>([]);
    const handleSelect = (rowIds: number[]) => setCheck(rowIds);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()
    
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const selectedRow = selectedRowIndex !== -1 ? Row[selectedRowIndex] : null;

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormConfirm = () => {
      handleFormClose()
    }

    const handleFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }


    const handleFilterClose = () => {
        setOpenFilter(undefined)
    }

    const filterItems = (value: string) => {
        handleFilterClose()
    }

    const initialValues = useMemo(() => {
        if (selectedRow) {
            return {
                employee: selectedRow.employee || '',
                role: selectedRow.role || '',
                department: selectedRow.department || '',
                questionnaires_published: selectedRow.questionnaires_published|| ''
                  };
        }
        return {
            employee: '',
            role: '',
            department: '',
            questionnaires_published: ''
        };
    }, [selectedRowIndex]);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`${routes.Voqinway}/${routes.Prepare}/${routes.QuestionnairesPublished}/${routes.Published}`)
      }

    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
                <Breadcrumbs texts={['Prepare Questionnaires', 'Questionnaires Definition']} />

            <Chip
                    icon={<TaskAlt sx={icon} />}
                    label={'Questionnaire Type'}
                    sx={chip}
                    onClick={handleFilterOpen}
                />

                <Menu
                    anchorEl={openFilter}
                    open={!!openFilter}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                marginTop: '10px',
                                width: '150px'
                            }
                        }
                    }}
            >
                {Object.values(statuses).map(item => (
                    <MenuItem onClick={() => filterItems(item.value)}
                        key={item.value}
                        value={item.value}
                    >
                        {item.value}
                    </MenuItem>
                ))}
            </Menu>
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
                <Spreadsheet
    order={[]}
                editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={Row}
                    columns={Column.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                    onRowClick={onClick}
                />

                <EditForm
                    open={formOpen}
                    handleClose={handleFormClose}
                    handleConfirm={handleFormConfirm}
                    title={'Edit Voqin Way'}
                    config={formConfig}
                    initialValues={initialValues}
                />
            </Box>
        </React.Fragment>
    );
}

export default QuestionnairesPublished;