export const Row = [
  {
    "id": 1,
    "supplier": "Boost",
    "zone": "Lisboa, Porto, Sintra,Arrábida,Algarve",
    "type": "Nautic,Team Building,City,Mountain,Gastronomic,Adventure,Cultural",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "Water X",
    "zone": "Lisboa,Lisbon Coast, Sesimbra, Algarve",
    "type": "Nautic,Adventure,Team Building",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Extremo Ambiente",
    "zone": "Douro, Lisboa, Porto, Sintra,Algarve",
    "type": "Mountain,Adventure,Team Building",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "Algarve Experience",
    "zone": "Algarve",
    "type": "Nautic,Adventure,Team Building",
    "ranking": "85%"
  }
]