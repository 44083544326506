import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Day",
    type: types.Text,
    name: "day",
    required: true
  },
  {
    title: "Date",
    type: types.Text,
    name: "date",
    required: true
  },
  {
    title: "Default text to P&L Group",
    type: types.Text,
    name: "default_text_to_pl_group",
    required: true
  },
  {
    title: "Program Item",
    type: types.Text,
    name: "program_item",
    required: true
  },
  {
    title: "Default Category",
    type: types.Text,
    name: "default_category",
    required: true
  },
  {
    title: "Default Localization",
    type: types.Text,
    name: "default_localization",
    required: true
  }
];
