import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Supplier",
    type: types.Chip,
    name: "supplier",
    required: true
  },
  {
    title: "Zone",
    type: types.Text,
    name: "zone",
    required: true
  },
  {
      title: "Type of Staff",
  type: types.Text,
  name: "type_of_staff",
  required: true
  },
  {
    title: "Ranking",
    type: types.Text,
    name: "ranking",
    required: true
  }

];
