const Column = {
  columns: [
{
  name: "day",
  title: "Day",
  width: 170,
  type: "text"
},
{
  name: "date",
  title: "Date",
  width: 170,
  type: "text"
},
{
  name: "default_text_to_pl_group",
  title: "Default text to P&L Group",
  width: 170,
  type: "text"
},
{
  name: "program_item",
  title: "Program Item",
  width: 170,
  type: "text"
},
{
  name: "default_category",
  title: "Default Category",
  width: 170,
  type: "text"
},
{
  name: "default_localization",
  title: "Default Localization",
  width: 170,
  type: "text"
}
  ]
}

export default Column