import React from 'react';
import {Outlet, Link, useLocation, useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChart from '@mui/icons-material/BarChart';
import Insights from '@mui/icons-material/Insights';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {useAppDispatch, useAppSelector} from 'app/hooks';
import {getLogout} from 'slices/auth';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import routes from 'enums/routes';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { People } from '@mui/icons-material';
import Workspaces from '@mui/icons-material/Workspaces'
import Logo from './Logo.png'
import VoqinWayLogo from './VoqinWayLogo.png'

const main = [
    {
        title: 'Import From CRM',
        path: routes.ImportFromCRM,
        icon: <ImportExportIcon />
    },
    {
        title: 'Projects',
        path: routes.Projects,
        icon: <Insights />
    },
    {
        title: 'Procurement',
        path: `${routes.Procurement}/${routes.RankingCriteria}`,
        icon: <QueryStatsIcon />
    },
    {
        title: 'Templates',
        path: routes.Templates,
        icon: <AccountTreeIcon />
    }
];

const secondary = [
    {
        title: 'Suppliers',
        path: routes.Suppliers,
        icon: <InventoryIcon />
    },
    {
        title: 'Team members',
        path: routes.Members,
        icon: <People />
    },
    {
        title: 'VOQIN\' Way',
        path: routes.Voqinway,
        icon: <Workspaces />
    },
]

const ProjectRoutes = [
    {
        title: 'Overview',
        path: routes.Overview,
    },
    {
        title: 'Profit & Loss',
        path: routes.Profit,
    },
    {
        title: 'Program',
        path: routes.Program,
    },
    {
        title: 'History',
        path: routes.ProjectHistory,
    },
    {
        title: 'Gantt',
        path: routes.ProjectGhant,
    },
    {
        title: 'Site Inspections',
        path: routes.SiteInspections,
    },
    {
        title: 'Risk Assessment',
        path: routes.RiskAssessment,
    },
    {
        title: 'Invoices',
        path: routes.Invoices,
    },
    {
        title: 'Balance',
        path: routes.Balance,
    },
    {
        title: 'Actions',
        path: routes.Actions,
    },
];

const ProcurementsRoutes = [
    {
        title: 'Ranking Criteria',
        path: routes.RankingCriteria,
    },
    {
        title: 'Search Calibration/Ranking',
        path: `${routes.Calibration}/${routes.Accomodation}`,
    }
];

const VoqinWayRoutes = [
    {
        title: 'Setup',
        path: `${routes.VoqinSetup}/${routes.EvaluationPeriods}`,
    },
    {
        title: 'Prepare Questionnaires',
        path: `${routes.Prepare}/${routes.QuestionnairesPublished}`,
    },
    {
        title: 'Answer Questionnaires',
        path: `${routes.Answer}/${routes.Evaluator}`,
    },
    {
        title: 'Results',
        path: `${routes.Results}/${routes.Evaluation}`,
    }
]

const PrepareRoutes = [
    {
        title:'Questionnaires Published',
        path: routes.QuestionnairesPublished
    }
]

const AnswerRoutes = [
    {
        title: 'Evaluator',
        path: routes.Evaluator
    },
]

const ResultsRoutes = [
    {
        title: 'Current Evaluation',
        path: routes.Evaluation
    },
    {   
        title: 'Past Evaluations',
        path: routes.PastEvaluations
    },
    {
        title: 'Progress per Voqer',
        path: routes.Progress
    }
]

const DefaultProjectsRoutes = [
    {
        title: 'Overview',
        path: routes.OverviewTemplate,
    }
    
]

const CalibrationRoutes = [
    {
        title: 'Accomodation',
        path: routes.Accomodation,
    },
    {
        title: 'F&B - Catering',
        path: routes.Catering
    },
    {
        title: 'F&B - Restaurants',
        path: routes.Restaurants
    },
    {
        title: 'Transportation',
        path: routes.Transportation
    },
    {
        title: 'Activities',
        path: routes.Activities
    },
    {
        title: 'Venues',
        path: routes.Venues
    },
    {
        title: 'Decor',
        path: routes.Decor
    },
    {
        title: 'Audio Visual',
        path: routes.AudioVisual
    },
    {
        title: 'Entertainment',
        path: routes.Entertainment
    },
    {
        title: 'Gifts',
        path: routes.Gifts
    },
    {
        title: 'Staff',
        path: routes.Staff
    },
    {
        title: 'Production',
        path: routes.Production
    },
    {
        title: 'Others',
        path: routes.Others
    }
]

const SetupRoutes = [
    {
        title:'Voquin Structure',
        path: routes.EvaluationPeriods,
    },
    {
        title: 'Default Questionnaires',
        path: routes.DefaultEvaluations
    }
]



const Container = () => {
    const user = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();
    const {pathname} = useLocation();
    const {projectId} = useParams();
    const {defaultId} = useParams();

    
    const isDefault = !!defaultId
    const isProcurement = pathname.includes(routes.Procurement);
    const isCalibration = pathname.includes(routes.Calibration)
    const isVoqinWay = pathname.includes(routes.Voqinway)
    const isSetup = pathname.includes(routes.VoqinSetup)
    const isPrepare = pathname.includes(routes.Prepare)
    const isAnswer = pathname.includes(routes.Answer)
    const isResults = pathname.includes(routes.Results)
    const isProject = !!projectId;
    const isMain = !isProject && !isProcurement && !isDefault &&!isVoqinWay;



    const onClick = () => {
        dispatch(getLogout());
    };

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250,
                }}
                PaperProps={{
                    sx: {
                        width: 250,
                    },
                }}
                variant="permanent"
                anchor="left">
                <Toolbar />
                    {isMain && 
                        <React.Fragment>
                            <List>
                                {main.map((item, index) => (
                                 <ListItem key={item.path} disablePadding>
                                    <ListItemButton component={Link} to={item.path} selected={pathname === item.path}>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                    {secondary.map((item, index) => (
                        <ListItem key={item.path} disablePadding>
                            <ListItemButton component={Link} to={item.path} selected={pathname === item.path}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    </List>
                
                        </React.Fragment>
}
                {isProject && (
                    <React.Fragment>
                        <List>
                            {ProjectRoutes.map((item, index) => {
                                const to = `${routes.Project}/${projectId}/${item.path}`;

                                return (
                                    <ListItem
                                        key={item.path}
                                        disablePadding>
                                        <ListItemButton
                                            component={Link}
                                            to={to}
                                            selected={to === pathname}>
                                            <ListItemIcon>
                                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Divider />
                        <ListItem
                            key={23452345}
                            disablePadding>
                            <ListItemButton
                                component={Link}
                                to={routes.Projects}>
                                <ListItemIcon>
                                    <ArrowBackIosIcon />
                                </ListItemIcon>
                                <ListItemText primary="All Projects" />
                            </ListItemButton>
                        </ListItem>
                    </React.Fragment>
                )}
                {isDefault && (
                    <React.Fragment>
                        <List>
                            {DefaultProjectsRoutes.map((item, index) => {
                                const to = `${routes.Template}/${defaultId}/${item.path}`;

                                return (
                                    <ListItem
                                        key={item.path}
                                        disablePadding>
                                        <ListItemButton
                                            component={Link}
                                            to={to}
                                            selected={to === pathname}>
                                            <ListItemIcon>
                                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Divider />
                        <ListItem
                            key={23452345}
                            disablePadding>
                            <ListItemButton
                                component={Link}
                                to={routes.Projects}>
                                <ListItemIcon>
                                    <ArrowBackIosIcon />
                                </ListItemIcon>
                                <ListItemText primary="Back" />
                            </ListItemButton>
                        </ListItem>
                    </React.Fragment>
                )}
{isCalibration ? (
    <React.Fragment>
        <List>
            {CalibrationRoutes.map((item, index) => {
                const to = `${routes.Procurement}/${routes.Calibration}/${item.path}`;

                return (
                    <ListItem
                        key={item.path}
                        disablePadding>
                        <ListItemButton
                            component={Link}
                            to={to}
                            selected={to === pathname}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        <Divider />
        <ListItem
            key={23452345}
            disablePadding>
            <ListItemButton
                component={Link}
                to={`${routes.Procurement}/${routes.RankingCriteria}`}>
                <ListItemIcon>
                    <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItemButton>
        </ListItem>
    </React.Fragment>
) : isProcurement ? (
    <React.Fragment>
        <List>
            {ProcurementsRoutes.map((item, index) => {
                const to = `${routes.Procurement}/${item.path}`;

                return (
                    <ListItem
                        key={item.path}
                        disablePadding>
                        <ListItemButton
                            component={Link}
                            to={to}
                            selected={to === pathname}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        <Divider />
        <ListItem
            key={23452345}
            disablePadding>
            <ListItemButton
                component={Link}
                to={routes.Projects}>
                <ListItemIcon>
                    <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItemButton>
        </ListItem>
    </React.Fragment>
) : null}


{isSetup ? (
    <React.Fragment>
        <List>
            {SetupRoutes.map((item, index) => {
                const to = `${routes.Voqinway}/${routes.VoqinSetup}/${item.path}`;

                return (
                    <ListItem
                        key={item.path}
                        disablePadding>
                        <ListItemButton
                            component={Link}
                            to={to}
                            selected={to === pathname}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        <Divider />
        <ListItem
            key={23452345}
            disablePadding>
            <ListItemButton
                component={Link}
                to={routes.Voqinway}>
                <ListItemIcon>
                    <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItemButton>
        </ListItem>
    </React.Fragment>
) : isPrepare ? (
    <React.Fragment>
        <List>
            {PrepareRoutes.map((item, index) => {
                const to = `${routes.Voqinway}/${routes.Prepare}/${item.path}`;

                return (
                    <ListItem
                        key={item.path}
                        disablePadding>
                        <ListItemButton
                            component={Link}
                            to={to}
                            selected={to === pathname}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        <Divider />
        <ListItem
            key={23452345}
            disablePadding>
            <ListItemButton
                component={Link}
                to={routes.Voqinway}>
                <ListItemIcon>
                    <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItemButton>
        </ListItem>
    </React.Fragment>
): isAnswer ? (
    <React.Fragment>
        <List>
            {AnswerRoutes.map((item, index) => {
                const to = `${routes.Voqinway}/${routes.Answer}/${item.path}`;

                return (
                    <ListItem
                        key={item.path}
                        disablePadding>
                        <ListItemButton
                            component={Link}
                            to={to}
                            selected={to === pathname}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        <Divider />
        <ListItem
            key={23452345}
            disablePadding>
            <ListItemButton
                component={Link}
                to={routes.Voqinway}>
                <ListItemIcon>
                    <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItemButton>
        </ListItem>
    </React.Fragment>
): 
 isResults ? (
    <React.Fragment>
        <List>
            {ResultsRoutes.map((item, index) => {
                const to = `${routes.Voqinway}/${routes.Results}/${item.path}`;

                return (
                    <ListItem
                        key={item.path}
                        disablePadding>
                        <ListItemButton
                            component={Link}
                            to={to}
                            selected={to === pathname}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        <Divider />
        <ListItem
            key={23452345}
            disablePadding>
            <ListItemButton
                component={Link}
                to={routes.Voqinway}>
                <ListItemIcon>
                    <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItemButton>
        </ListItem>
    </React.Fragment>
):isVoqinWay ? (
    <React.Fragment>
        <List>
            {VoqinWayRoutes.map((item, index) => {
                const to = `${routes.Voqinway}/${item.path}`;

                return (
                    <ListItem
                        key={item.path}
                        disablePadding>
                        <ListItemButton
                            component={Link}
                            to={to}
                            selected={to === pathname}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        <Divider />
        <ListItem
            key={23452345}
            disablePadding>
            <ListItemButton
                component={Link}
                to={routes.Projects}>
                <ListItemIcon>
                    <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
            </ListItemButton>
        </ListItem>
    </React.Fragment>
) : null}


                
            </Drawer>
            <AppBar
                position="fixed"
                sx={{zIndex: 1300}}>
                <Toolbar>
                    {isVoqinWay ? (
                        <img src={VoqinWayLogo} alt='Logo' style={{marginRight: '10px'}} />
                    ) : (
                        <img src={Logo} alt='Logo' style={{ marginRight: '10px' }} />
                    )}
                    <Typography
                        component="div"
                        sx={{flexGrow: 1}}></Typography>
                    <Tooltip
                        title="Logout"
                        placement="bottom">
                        <IconButton
                            color="info"
                            onClick={onClick}
                            sx={{marginLeft: 'auto'}}>
                            <Logout />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={user && user.name}
                        placement="bottom">
                        <IconButton
                            size="large"
                            color="info">
                            <AccountCircle />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Box
                sx={{flexGrow: 1, marginTop: '64px', minWidth: 0, minHeight: 0}}
                component="main">
                <Outlet />
            </Box>
        </React.Fragment>
    );
};

export default Container;

