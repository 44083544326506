const Column = {
  columns: [
{
  name: "employee",
  title: "Employee",
  width: 170,
  type: "text"
},
{
  name: "manager",
  title: "Manager",
  width: 170,
  type: "text"
},
{
  name: "role",
  title: "Role",
  width: 170,
  type: "text"
},
{
  name: "department",
  title: "Department",
  width: 170,
  type: "text"
},
{
  name: "complete",
  title: "#Complete",
  width: 170,
  type: "text"
}
  ]
}

export default Column