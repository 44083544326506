import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Default Questionnaire",
    type: types.Text,
    name: "default_questionnaire",
    required: true
  },
  {
    title: "Last Update",
    type: types.Text,
    name: "last_update",
    required: true
  }
];
