export const Row = [
  {
    "id": 1,
    "supplier": "Sud",
    "zone": "Lisboa",
    "subzone": "Belém",
    "capacity": 350,
    "exclusive_catering": "Yes",
    "exclusive_audiovisuals": "Yes",
    "venue_type": "Design",
    "status_color": "#81d4fa",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "Convento do Beato",
    "zone": "Lisboa",
    "subzone": "Beato",
    "capacity": 1000,
    "exclusive_catering": "No",
    "exclusive_audiovisuals": "No",
    "venue_type": "Design",
    "status_color": "#81d4fa",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Palácio de Queluz",
    "zone": "Lisboa",
    "subzone": "Queluz",
    "capacity": 200,
    "exclusive_catering": "No",
    "exclusive_audiovisuals": "No",
    "venue_type": "Palace",
    "status_color": "#f48fb1",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "Palácio da Bolsa",
    "zone": "Porto",
    "subzone": "Ribeira",
    "capacity": 300,
    "exclusive_catering": "No",
    "exclusive_audiovisuals": "No",
    "venue_type": "Palace",
    "status_color": "#f48fb1",
    "ranking": "85%"
  },
  {
    "id": 5,
    "supplier": "Pousada de Óbidos",
    "zone": "Oeste",
    "subzone": "Óbidos",
    "capacity": 80,
    "exclusive_catering": "Yes",
    "exclusive_audiovisuals": "No",
    "ranking": "85%"
  },
  {
    "id": 6,
    "supplier": "Herdade do Esporção",
    "zone": "Alentejo",
    "subzone": "Évora",
    "exclusive_catering": "Yes",
    "exclusive_audiovisuals": "No",
    "venue_type": "Quinta",
    "status_color":"#9fa8da",
    "ranking": "85%"
  },
  {
    "id": 7,
    "supplier": "Centro de Congressos Lisboa",
    "zone": "Lisboa",
    "exclusive_audiovisuals": "Yes",
    "venue_type": "Congress Center",
    "status_color": "#d4fa81",
    "ranking": "85%"
  }
]