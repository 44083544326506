import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Evaluatee",
    type: types.Text,
    name: "evaluee",
    required: true
  },
  {
    title: "Evaluator",
    type: types.Text,
    name: "evaluator",
    required: true
  },
  {
    title: "Type",
    type: types.Text,
    name: "type",
    required: true
  },
  {
    title: "Role",
    type: types.Text,
    name: "role",
    required: true
  },
  {
    title: "Complete",
    type: types.Chip,
    name: "complete",
    required: true
  }
];
