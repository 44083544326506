export const Row = [
  {
    "id": 1,
    "employee": "John Carpenter",
    "manager": "Diogo Assis",
    "role": "CCO",
    "department": "Sales",
    "complete": "4/6"
  },
  {
    "id": 2,
    "employee": "Diogo Assis",
    "role": "CEO",
    "complete": "2/6"
  },
  {
    "id": 3,
    "employee": "Robert, Palmer",
    "manager": "John Carpenter",
    "role": "Sales Director",
    "department": "Sales",
    "complete": "3/5"
  },
  {
    "id": 4,
    "employee": "Randy, Heather",
    "manager": "John Carpenter",
    "role": "Sales Specialist",
    "department": "Sales",
    "complete": "3/5"
  },
  {
    "id": 5,
    "employee": "Alvin, Silva",
    "manager": "Marc Rees",
    "role": "Project Manager",
    "department": "Solutions",
    "complete": "2/7"
  },
  {
    "id": 6,
    "employee": "Marc Rees",
    "manager": "Diogo Assis",
    "role": "COO",
    "department": "Solutions",
    "complete": "2/6"
  },
  {
    "id": 7,
    "employee": "Pat Heather",
    "manager": "Marc Rees",
    "role": "Project Coordinator",
    "department": "Solutions",
    "complete": "3/7"
  },
  {
    "id": 8,
    "employee": "Mike Siri",
    "manager": "Pat Heather",
    "role": "Team Leader",
    "department": "Solutions",
    "complete": "2/7"
  },
  {
    "id": 9,
    "employee": "Kay  Star",
    "manager": "Mike Siri",
    "role": "Team member",
    "department": "Solutions",
    "complete": "3/7"
  },
  {
    "id": 10,
    "employee": "Susan Vega",
    "manager": "Diogo Assis",
    "role": "CFO",
    "department": "Finance",
    "complete": "4/7"
  },
  {
    "id": 11,
    "employee": "Carol Viegas",
    "manager": "Susan Vega",
    "role": "Financial Controler",
    "department": "Finance",
    "complete": "5/7"
  },
  {
    "id": 12,
    "employee": "Marc Foster",
    "manager": "Mike Siri",
    "role": "Team member",
    "department": "Solutions",
    "complete": "2/7"
  },
  {
    "id": 13,
    "employee": "Wlliam Defoe",
    "manager": "Susan Vega",
    "role": "Team member",
    "department": "Finance",
    "complete": "0/7"
  },
  {
    "id": 14,
    "employee": "Jeoffrey Silva",
    "manager": "Alvin, Silva",
    "role": "Team member",
    "department": "Solutions",
    "complete": "3/7"
  },
  {
    "id": 15,
    "employee": "Susana Lima",
    "manager": "Alvin, Silva",
    "role": "Team member",
    "department": "Solutions",
    "complete": "2/7"
  },
  {
    "id": 16,
    "employee": "Manuel Castro",
    "manager": "John Carpenter",
    "role": "Team member",
    "department": "Solutions",
    "complete": "6/7"
  }
]