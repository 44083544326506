export const Row = [
  {
    "id": 1,
    "supplier": " Pois Selection",
    "gift_type": "Gastronomy, Tradicional",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "The Lisbon Shop",
    "gift_type": "***All",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Enerre",
    "gift_type": "Merchandising, Clothes",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "Vida Portuguesa",
    "gift_type": "Tradicional,Gastronomy",
    "ranking": "85%"
  }
]