const Column = {
  columns: [
{
  name: "project",
  title: "Project",
  width: 170,
  type: "text"
},
{
  name: "customer",
  title: "Customer",
  width: 170,
  type: "text"
},
{
  name: "budget_amount_",
  title: "Budget Amount ",
  width: 170,
  type: "text"
},
{
  name: "estimated_date_",
  title: "Estimated Date ",
  width: 170,
  type: "date"
},
{
  name: "contact",
  title: "Contact",
  width: 170,
  type: "protected"
},
{
  name: "voqin_status",
  title: "Voqin Status",
  width: 170,
  type: "status"
},
{
  name: "referral",
  title: "Referral",
  width: 170,
  type: "client"
},
{
  name: "regimen",
  title: "Regimen",
  width: 170,
  type: "text"
},
{
  name: "client_agency",
  title: "Client Agency",
  width: 170,
  type: "client"
},
{
  name: "sales_office",
  title: "Sales Office",
  width: 170,
  type: "client"
},
{
  name: "probability",
  title: "Probability",
  width: 170,
  type: "text"
},
{
  name: "voqin_points",
  title: "Voqin Points",
  width: 170,
  type: "text"
},
{
  name: "comments",
  title: "Comments",
  width: 170,
  type: "text"
},
  ]
}

export default Column