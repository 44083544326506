import React, { useState } from 'react';
import { Box } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';
import { Row } from './row';
import Column from './column';
import EditForm from 'components/EditForm';
import { formConfig } from './formConfig';
import AnswerModal from './answerModal';

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const Evaluator = () => {
    const [checked, setCheck] = useState<number[]>([]);
    const handleSelect = (rowIds: number[]) => setCheck(rowIds);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [formOpen, setFormOpen] = useState<boolean>(false);

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormConfirm = () => {
      handleFormClose()
    }



    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
                <Breadcrumbs texts={['VOQIN Way', 'Evaluator - John Carpenter']} />
            </Box>
 
            <Box style={{ height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex' }}>
               
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={Row}
                    columns={Column.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                    onRowClick={(selectedRow) => {
                        const rowIndex = convertTo1Indexed(selectedRow);
                        setSelectedRowIndex(rowIndex);
                        setModalOpen(true);
                    }}
                />
                 <AnswerModal open={modalOpen} onClose={() => setModalOpen(false)} />

                <EditForm
                    open={formOpen}
                    handleClose={handleFormClose}
                    handleConfirm={handleFormConfirm}
                    title={'Edit Voqin Way'}
                    config={formConfig}
                    initialValues={{}}
                />
            </Box>
        </React.Fragment>
    );
}

export default Evaluator;