export const Row = [
  {
    "id": 1,
    "employee": "John Carpenter",
    "manager": "Diogo Assis",
    "role": "CCO",
    "department": "Sales",
    "adaptability": 4.7,
    "resilience": 4.5,
    "empathy": 4.0,
    "ownership": 3.8,
    "collaborative": 4.1,
    "team_management": 4.1,
    "leadership": 3.8,
    "voqins_global_vision": 3.8,
    "kpi_average": 3.0,
    "cultural": "83%",
    "business": "60%",
    "global_fit": "71%",
    "completness": "4/6"
  },
  {
    "id": 2,
    "employee": "Marc Rees",
    "manager": "Diogo Assis",
    "role": "COO",
    "department": "Projects Dept",
    "adaptability": 4.4,
    "resilience": 3.4,
    "empathy": 4.7,
    "ownership": 4.8,
    "collaborative": 4.2,
    "team_management": 4.0,
    "leadership": 4.7,
    "voqins_global_vision": 4.1,
    "kpi_average": 2.5,
    "cultural": "85%",
    "business": "38%",
    "global_fit": "61%",
    "completness": "5/7"
  },
  {
    "id": 3,
    "employee": "Susan Vega",
    "manager": "Diogo Assis",
    "role": "CFO",
    "department": "Finance",
    "adaptability": 4.4,
    "resilience": 4.4,
    "empathy": 5.0,
    "ownership": 4.4,
    "collaborative": 4.5,
    "team_management": 5.0,
    "leadership": 4.8,
    "voqins_global_vision": 4.7,
    "kpi_average": 5.0,
    "cultural": "93%",
    "business": "100%",
    "global_fit": "72%",
    "completness": "4/4"
  },
  {
    "id": 4,
    "employee": "Marc Foster",
    "manager": "Mike Siri",
    "role": "Team Member",
    "department": "Projects Department",
    "adaptability": 3.2,
    "resilience": 3.2,
    "empathy": 2.3,
    "ownership": 2.3,
    "collaborative": 2.7,
    "team_management": null,
    "leadership": null,
    "voqins_global_vision": 2.3,
    "kpi_average": null,
    "cultural": "80%",
    "business": "",
    "global_fit": "80%",
    "completness": "4/5"
  },
  {
    "id": 5,
    "employee": "Kay Star",
    "manager": "Mike Siri",
    "role": "Team Member",
    "department": "Projects Department",
    "adaptability": 2.8,
    "resilience": 2.2,
    "empathy": 3.0,
    "ownership": 3.3,
    "collaborative": 2.7,
    "team_management": null,
    "leadership": null,
    "voqins_global_vision": 2.7,
    "kpi_average": null,
    "cultural": "83%",
    "business": "",
    "global_fit": "83%",
    "completness": "5/5"
  }
]