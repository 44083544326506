import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import RankingCriteria from './RankingCriteria.png'

interface IPropsTestModal {
    open: boolean
    onClose: Function
}

const ModalRow1 = (props: IPropsTestModal) => {
    const [open, setOpen] = useState<boolean>(props.open)

    useEffect(() => setOpen(props.open), [props.open])

    const onClose = (): void => props.onClose()

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogContent sx={{ padding: 4 }}>
                    <img src={RankingCriteria} style={{ width: 810 }} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ModalRow1
