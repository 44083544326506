const Column = {
  columns: [
{
  name: "question_code",
  title: "Question Code",
  width: 170,
  type: "text"
},
{
  name: "header",
  title: "Header",
  width: 170,
  type: "text"
},
{
  name: "question",
  title: "Question",
  width: 1300,
  type: "text"
},
{
  name: "order",
  title: "Order",
  width: 170,
  type: "text"
},
{
  name: "type",
  title: "Question Type",
  width: 170,
  type: "status"
},

  ]
}

export default Column