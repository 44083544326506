export const overview = [
    {
        "id": 1,
        "meeting_template":"Meeting Template"
    },

    {
        "id":2,
        "congress_template":"Congress Template"
    }
]