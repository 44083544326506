const Column = {
  columns: [
{
  name: "supplier",
  title: "Supplier",
  width: 170,
  type: "text"
},
{
  name: "zone",
  title: "Zone",
  width: 170,
  type: "text"
},
{
  name: "subzone",
  title: "Sub-Zone",
  width: 170,
  type: "text"
},
{
  name: "capacity",
  title: "Capacity",
  width: 170,
  type: "text"
},
{
  name: "exclusive_catering",
  title: "Exclusive Catering",
  width: 170,
  type: "text"
},
{
  name: "exclusive_audiovisuals",
  title: "Exclusive AudioVisuals",
  width: 170,
  type: "text"
},
{
  name: "venue_type",
  title: "Venue Type",
  width: 170,
  type: "status"
},
{
  name: "ranking",
  title: "Ranking",
  width: 170,
  type: "text"
}
  ]
}

export default Column