import React, { useMemo, useState } from 'react'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import { Row } from './rowAccomodation'
import Column from './columnAccomodation'
import { formConfig } from './formConfigAccomodation'
import TestModal from './ModalRow1'
import EditForm from './EditForm'


const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78

const Accomodation = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [checked, setCheck] = useState<number[]>([])
    const handleSelect = (rowIds: number[]) => setCheck(rowIds)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;

    const handleFormOpen = (rowIndex: number) => {
        if(rowIndex >= 0){
        const oneIndexedRowIndex = convertTo1Indexed(rowIndex);
        setSelectedRowIndex(oneIndexedRowIndex);
        setOpenForm(true);
        }
      };
  
    const handleFormClose = () => {
      setOpenForm(false)
    }
  
    const handleConfirm = () => {
      handleFormClose()
    }

    const handleRanking = () => {
        setModalOpen(true)
      }
    
    const selectedRow = selectedRowIndex !== null ? Row[selectedRowIndex] : null;

    const initialValues = useMemo(() => {
      if (selectedRow) {
        return {
          supplier: selectedRow.supplier || '',
          zone: selectedRow.zone || '',
          max_capacity: selectedRow.max_capacity || '',
          meeting_room: selectedRow.meeting_room || '',
          max_meeting_room_capacity: selectedRow.max_meeting_room_capacity || null,
          stars: selectedRow.stars || null,
          ranking: selectedRow.ranking || '',
        
        };
      }
      return {
        supplier: '',
        zone: '',
        max_capacity: '',
        meeting_room: '',
        max_meeting_room_capacity: null,
        stars: null,
        ranking: '',
      };
    }, [selectedRowIndex]);

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['Search Calibration', 'Accomodation']} />
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
            <Spreadsheet
                order={[]}
                editableHeader={false}
                fixedCellWidth={100}
                checkedRows={checked}
                propId='id'
                rows={Row}
                columns={Column.columns}
                heightRow={50}
                onCheck={handleSelect}
                renderCell={renderCell}
                onRowClick={(rowId) => handleFormOpen(rowId)}
               
            />
            <EditForm
            open={openForm}
            handleClose={handleFormClose}
            handleConfirm={handleConfirm}
            handleRanking={handleRanking}
            title={'Edit Category Search'}
            config={formConfig} 
            initialValues={initialValues}                                     
            />
            <TestModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            ></TestModal>
            </Box>
        </React.Fragment>
    )
}

export default Accomodation

