import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoute } from 'api/enums';
import { IReqData, IReqIds, IEmployee } from 'api/interfaces';
import api from 'utils/api';

interface EmployeeState {
    employees: IEmployee[];
    pending: boolean;
}

const initialState: EmployeeState = {
    employees: [],
    pending: false
};

export const getEmployees = createAsyncThunk('employee/getEmployees', async () => {
    const response = await api(ApiRoute.getemployees);
    return response.data;
});

export const createEmployee = createAsyncThunk('employee/createEmployee', async (data: IReqData<IEmployee>) => {
    const response = await api(ApiRoute.createemployees, data);
    return response.data;
});

export const updateEmployee = createAsyncThunk('employee/updateEmployee', async (data: IReqData<IEmployee>) => {
    const response = await api(ApiRoute.updateemployees, data);
    return response.data;
});

export const deleteEmployee = createAsyncThunk('employee/deleteEmployee', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteemployees, ids);
    return response.data;
});

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEmployees.pending, (state) => {
                state.pending = true;
            })
            .addCase(getEmployees.fulfilled, (state, action) => {
                state.employees = action.payload;
                state.pending = false;
            })
            .addCase(getEmployees.rejected, (state) => {
                state.pending = false;
            })
            .addCase(createEmployee.pending, (state) => {
                state.pending = true;
            })
            .addCase(createEmployee.fulfilled, (state, action) => {
                state.employees.push(...action.payload);
                state.pending = false;
            })
            .addCase(createEmployee.rejected, (state) => {
                state.pending = false;
            })
            .addCase(deleteEmployee.pending, (state) => {
                state.pending = true;
            })
            .addCase(deleteEmployee.fulfilled, (state, action) => {
                state.employees = state.employees.filter(employee => !action.meta.arg.ids.includes(employee.id));
                state.pending = false;
            })
            .addCase(deleteEmployee.rejected, (state) => {
                state.pending = false;
            })
            .addCase(updateEmployee.pending, (state) => {
                state.pending = true;
            })
            .addCase(updateEmployee.fulfilled, (state, action) => {
                action.payload.forEach((updatedEmployee: IEmployee) => {
                    const index = state.employees.findIndex(employee => employee.id === updatedEmployee.id);
                    if (index !== -1) {
                        state.employees[index] = updatedEmployee;
                    }
                });
                state.pending = false;
            })
            .addCase(updateEmployee.rejected, (state) => {
                state.pending = false;
            });
    },
});

export default employeeSlice;
