import moment from "moment";

export enum SortingType {
    Ascending = 1,
    Descending = 2
}

export async function getHash(pass: string): Promise<string> {
    return pass;
}

export function getTimestamp(): number {
    return Date.now();
}

export function uniq<T>(array: T[]): T[] {
    return array.filter((value, index) => array.indexOf(value) === index);
}

export function normalize(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function standartize<T>(value: T | T[keyof T]): string {
    if (typeof value === 'string' && value) {
        return normalize(value).toLowerCase();
    }
    return `${value}`; 
}

export function getShortName(original: string): string {
    const separators: string[] = [' ', '.', '_', '-', '@'];
    let splitted: string[] = [];
    const name: string = `${original.toUpperCase().trim()}`;
    for (let i = 0; i < name.length; i++) {
        if (separators.includes(name[i])) {
            splitted = name.split(name[i]);
            break;
        }
    }
    if (splitted.length > 1) {
        return splitted[0][0] + splitted[1][0];
    } else if (name.length > 1) {
        return name.slice(0, 2);
    }
    return name;
}

/* math */

export const boolsum = (arr: boolean[]): boolean => arr.reduce((a: boolean, b: boolean) => a && b, true);

export const sum = (arr: number[]): number => arr.reduce((a: number, b: number) => (a || 0) + (b || 0), 0);

/* array utils */

export function sortBy<T>(array: T[], prop: keyof T, type: SortingType = SortingType.Ascending): T[] {
    array.sort((a: T, b: T): number => {
        let aProp: T[keyof T] = a[prop];
        let bProp: T[keyof T] = b[prop];
        if (typeof aProp === 'string') {
            aProp = aProp.toLowerCase() as unknown as T[keyof T];
        }
        if (typeof bProp === 'string') {
            bProp = bProp.toLowerCase() as unknown as T[keyof T];
        }
        return (aProp > bProp ? 1 : -1) * (type === SortingType.Ascending ? 1 : -1);
    });
    return array;
}

export function sortByOrder<T, P>(array: T[], prop: keyof T, order: (string | number)[]): T[] {
    const maxOrder: number = Math.pow(2, 32);
    return array.sort((a: T, b: T) => {
        let aIndex: string | number = order.indexOf(a[prop] as unknown as (string | number));
        let bIndex: string | number = order.indexOf(b[prop] as unknown as (string | number));
        aIndex = aIndex < 0 ? maxOrder : aIndex;
        bIndex = bIndex < 0 ? maxOrder : bIndex;
        return aIndex - bIndex;
    });
}

export function filter<T>(array: T[], pattern: string, props: (keyof T)[] = []): T[] {
    const p: string = `${normalize(pattern).toLowerCase()}`;
    const patterns: string[] = uniq(p.split(' ')).filter((v: string) => !!v);
    if (patterns.length) {
        return array.filter((item: T) => {
            let matchers: string[] = [];
            if (props.length) {
                for (let i = 0; i < props.length; i++) {
                    matchers.push(standartize(item[props[i]]));
                }
            } else {
                matchers.push(standartize(item));
            }
            matchers = matchers.filter((v: string) => !!v);
            if (matchers.length) {
                let result: boolean = true;
                for (let i = 0; i < patterns.length; i++) {
                    let patternResult = false;
                    for (let j = 0; j < matchers.length; j++) {
                        patternResult = patternResult || matchers[j].includes(patterns[i]);
                    }
                    result = result && patternResult;
                }
                return result;
            }
            return false;
        });
    } else {
        return [...array];
    }
}

export function replaceByProp<T>(array: T[], item: T, prop: keyof T): T[] {
    const index: number = array.findIndex((i: T) => i[prop] === item[prop]);
    if (index > -1) {
        const result: T[] = [...array];
        result[index] = item;
        return result;
    }
    return array;
}

export function deleteByProp<T>(array: T[], prop: keyof T, value: T[keyof T]): T[] {
    return array.filter((i: T) => i[prop] !== value);
}

/* formatter utils */

export const formatPrice = (value: number, currency: string = '€', left: boolean = true): string => {
    return left ? `${currency} ${formatNumber(value, 2)}` : `${formatNumber(value, 2)} ${currency}`;
};

export const formatNumber = (value: number, decimal: number = 0): string => {
    const parts: string[] = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    parts[1] = `${parts[1] || ''}${new Array(decimal).fill('0').join('')}`.slice(0, decimal);
    if (parts[1]) {
        return `${parts[0]}.${parts[1]}`;
    }
    return parts[0];
}

export const formatDate = (iso: Date, format: string = 'DD MMM YYYY HH:mm'): string => {
    return moment(iso).format(format);
};

export function log(message: any, color?: string): void {
    if (typeof message === 'string' && color) {
        console.log(`%c ${message}`, `color: ${color}; font-weight: bold;`);
    } else {
        console.log(message);
    }
}

/* render */

export const renderDate = (value: any) => {
    if (value) {
        const d = new Date(value)
        const date = d.toLocaleDateString()
        const time = d.toLocaleTimeString().split(":").slice(0, 2).join(':')
        return `${date} ${time}`
    }
    return '-';
}

export const renderDateOnly = (value: any) => {
    if (value) {
        const d = new Date(value)
        const date = d.toLocaleDateString()
        return `${date}`
    }
    return '-';
}

export const renderMoney = (value: any) => {
    const point = '.'
    if (value) {
        const num = Number(value);
        if (!isNaN(num)) {
            const parts = num.toFixed(2).split('.');
            const first = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return `€ ${first}${point}${parts[1]}`;
        }
    }
    return '-';
}

export const renderPercent = (value: any) => {
    const point = '.'
    if (value) {
        const num = Number(value);
        if (!isNaN(num)) {
            return `${(num * 100).toFixed(2).replace('.', point)} %`;
        }
        return value;
    }
    return '-';
}