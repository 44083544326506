const Column = {
  columns: [
{
  name: "category",
  title: "Category",
  width: 170,
  type: "text"
},
{
  name: "criteria1",
  title: "Criteria 1",
  width: 170,
  type: "text"
},
{
  name: "weight1",
  title: "Weight 1",
  width: 170,
  type: "text"
},
{
  name: "criteria2",
  title: "Criteria 2",
  width: 170,
  type: "text"
},
{
  name: "weight2",
  title: "Weight 2",
  width: 170,
  type: "text"
},
{
  name: "criteria3",
  title: "Criteria 3",
  width: 170,
  type: "text"
},
{
  name: "weight3",
  title: "Weight 3",
  width: 170,
  type: "text"
},
{
  name: "criteria4",
  title: "Criteria 4",
  width: 170,
  type: "text"
},
{
  name: "weight4",
  title: "Weight 4",
  width: 170,
  type: "text"
},
{
  name: "criteria5",
  title: "Criteria 5",
  width: 170,
  type: "text"
},
{
  name: "weight5",
  title: "Weight 5",
  width: 170,
  type: "text"
},
{
  name: "criteria6",
  title: "Criteria 6",
  width: 170,
  type: "text"
},
{
  name: "weight6",
  title: "Weight 6",
  width: 170,
  type: "text"
},
{
  name: "criteria7",
  title: "Criteria 7",
  width: 170,
  type: "text"
},
{
  name: "weight7",
  title: "Weight 7",
  width: 170,
  type: "text"
},
{
  name: "criteria8",
  title: "Criteria 8",
  width: 170,
  type: "text"
},
{
  name: "weight8",
  title: "Weight 8",
  width: 170,
  type: "text"
},
{
  name: "total",
  title: "Total",
  width: 170,
  type: "text"
}
  ]
}

export default Column