import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Supplier",
    type: types.Text,
    name: "supplier",
    required: true
  },
  {
    title: "Zone",
    type: types.Text,
    name: "zone",
    required: true
  },
  {
    title: "Max.Capacity",
    type: types.Text,
    name: "max_capacity",
    required: true
  },
  {
    title: "Meeting Room",
    type: types.Text,
    name: "meeting_room",
    required: true
  },
  {
    title: "Max.Meeting Room Capacity",
    type: types.Text,
    name: "max_meeting_room_capacity",
    required: true
  },
  {
    title: "Stars",
    type: types.Text,
    name: "stars",
    required: true
  },
  {
    title: "Ranking",
    type: types.Text,
    name: "ranking",
    required: true
  }
];
