const Column = {
  columns: [
{
  name: "evaluee",
  title: "Evaluaee",
  width: 170,
  type: "text"
},
{
  name: "evaluator",
  title: "Evaluator",
  width: 170,
  type: "text"
},
{
  name: "type",
  title: "Type",
  width: 170,
  type: "text"
},
{
  name: "role",
  title: "Role",
  width: 170,
  type: "text"
},
{
  name: "complete",
  title: "Complete",
  width: 170,
  type: "status"
}
  ]
}

export default Column