import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import Root from "containers/Root";
import Container from "containers/Container";
import Auth from "containers/Auth";
import Signin from "containers/Signin";
import Program from "containers/Project/Program";
import routes from "enums/routes";
import { store } from "./app/store";
import theme from "theme";
import Suppliers from "containers/Suppliers";
import TeamMembers from "containers/TeamMembers";
import SiteInspections from "containers/Project/SiteInspections";
import RiskAssessment from "containers/Project/RiskAssessment";
import Invoices from "containers/Project/Invoices";
import Balance from "containers/Project/Balance";
import Actions from "containers/Project/Actions";
import ProfitLoss from "containers/Project/Profit";
import ProjectsList from "containers/ProjectsList";
import ProjectHistory from "containers/ProjectHistory";
import ProjectGhant from "containers/Project/Ghant";
import Overview from "containers/Project/Overview";
import ImportFromCRM from "containers/ImportFromCRM";
import ProductLaunchesDefaultProject from "containers/DefaultProjects/productLaunchDefaultProject";
import OverviewTemplate from "containers/DefaultProjects/meetingTemplate";
import DefaultProjects from "containers/DefaultProjects";
import Accomodation from "containers/Procurement/SearchCalibration/Accomodation/accomodation";
import Catering from "containers/Procurement/SearchCalibration/F&B - Catering/catering";
import Restaurants from "containers/Procurement/SearchCalibration/F&B - Restaurants/restaurants";
import Transportation from "containers/Procurement/SearchCalibration/Transportation/transportation";
import Activities from "containers/Procurement/SearchCalibration/Activities/activities";
import Venues from "containers/Procurement/SearchCalibration/Venues/venues";
import Decor from "containers/Procurement/SearchCalibration/Decor/decor";
import AudioVisual from "containers/Procurement/SearchCalibration/AudioVisual/audiovisual";
import Entertainment from "containers/Procurement/SearchCalibration/Entertainment/entertainment";
import Gifts from "containers/Procurement/SearchCalibration/Gifts/gifts";
import Staff from "containers/Procurement/SearchCalibration/Staff/staff";
import Production from "containers/Procurement/SearchCalibration/Production/production";
import Others from "containers/Procurement/SearchCalibration/Others/others";
import RankingCriteria from "containers/Procurement/RankingCriteria/rankingCriteria";
import DefaultQuestionaires from "containers/VoqinWay/Setup/DefaultQuestionaires";
import ChiefCLevel from "containers/VoqinWay/Setup/DefaultQuestionaires/ChiefCLevel";
import Evaluator from "containers/VoqinWay/Answer";
import PastEvaluationsPeriods from "containers/VoqinWay/Results/PastEvaluations/PastEvaluationsPeriods/index2";
import QuestionnairesPublished from "containers/VoqinWay/PrepareQuestionnaires/QuestionnairesPublished";
import CurrentEvaluation from "containers/VoqinWay/Results/CurrentEvaluation";
import VoqinStructure from "containers/VoqinWay/Setup/EvaluationPeriods";
import QuestionnairesToBePublished from "containers/VoqinWay/PrepareQuestionnaires/QuestionnairesPublished/QuestionnairesToBePublished";
import PastEvaluations from "containers/VoqinWay/Results/PastEvaluations/PastEvaluations";
import ProgressPerVoquer from "containers/VoqinWay/Results/ProgressPerVoqer/Progress";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route path={`${routes.Signin}`} element={<Signin />} />
      <Route element={<Auth />}>
        <Route element={<Container />}>
          <Route path={`${routes.ImportFromCRM}`} element={<ImportFromCRM />} />
          <Route path={`${routes.Projects}`} element={<ProjectsList />} />
          <Route path={`${routes.Suppliers}`} element={<Suppliers />} />
          <Route path={routes.Templates} element={<DefaultProjects/>} />
          <Route path={`${routes.Members}`} element={<TeamMembers />} />

          <Route path={`${routes.Project}/:projectId`}>
            <Route path={routes.Overview} element={<Overview />} />
            <Route path={routes.Profit} element={<ProfitLoss projectId={1} />} />
            <Route path={routes.Program} element={<Program />} />
            <Route path={routes.ProjectHistory} element={<ProjectHistory />} />
            <Route path={routes.ProjectGhant} element={<ProjectGhant />} />
            <Route path={routes.SiteInspections} element={<SiteInspections />}/>
            <Route path={routes.RiskAssessment} element={<RiskAssessment />} />
            <Route path={routes.Invoices} element={<Invoices />} />
            <Route path={routes.Balance} element={<Balance />} />
            <Route path={routes.Actions} element={<Actions />} />
          </Route>

          <Route path={`${routes.Template}/:defaultId`}>
            <Route path={routes.OverviewTemplate} element={<OverviewTemplate />} />
            <Route path={routes.ProductLaunchesDefaultProject} element={<ProductLaunchesDefaultProject />} />
          </Route> 

          <Route path={`${routes.Procurement}`}>
            <Route path={routes.RankingCriteria} element={<RankingCriteria />} />
            <Route path={`${routes.Calibration}`}>
              <Route path={routes.Accomodation} element={<Accomodation />} />
              <Route path={routes.Catering} element={<Catering />} />
              <Route path={routes.Restaurants} element={<Restaurants />} />
              <Route path={routes.Transportation} element={<Transportation />} />
              <Route path={routes.Activities} element={<Activities />} />
              <Route path={routes.Venues} element={<Venues />} />
              <Route path={routes.Decor} element={<Decor />} />
              <Route path={routes.AudioVisual} element={<AudioVisual />} />
              <Route path={routes.Entertainment} element={<Entertainment />} />
              <Route path={routes.Gifts} element={<Gifts />} />
              <Route path={routes.Staff} element={<Staff />} />
              <Route path={routes.Production} element={<Production />} />
              <Route path={routes.Others} element={<Others />} />
            </Route>
          </Route>

          <Route path={`${routes.Voqinway}`}>
            <Route path={`${routes.VoqinSetup}`}>
              <Route path={`${routes.EvaluationPeriods}`} element={<VoqinStructure />}/>
              <Route path={routes.DefaultEvaluations} element={<DefaultQuestionaires />} />
                <Route path={`${routes.DefaultEvaluations}`} >
                  <Route path= {routes.ChiefCLevel} element={<ChiefCLevel />}/>
                </Route> 
            </Route>

            <Route path={`${routes.Prepare}`}>
              <Route path={routes.QuestionnairesPublished} element ={<QuestionnairesPublished />}/>
                <Route path={`${routes.QuestionnairesPublished}`}>
                  <Route path={routes.Published}element={<QuestionnairesToBePublished/>}/>
                </Route>
            </Route>

            <Route path ={`${routes.Answer}`}>
              <Route path= {routes.Evaluator} element={<Evaluator/>}/>
            </Route>

            <Route path ={`${routes.Results}`}>
              <Route path= {routes.Evaluation} element={<CurrentEvaluation/>}/>
              <Route path={routes.PastEvaluations} element={<PastEvaluations />}/>
              <Route path={routes.Progress} element={<ProgressPerVoquer/>}/>
                <Route path={`${routes.PastEvaluations}`}>
                  <Route path={routes.PastEvaluationsPeriods} element={<PastEvaluationsPeriods/>}/>
                </Route>
              </Route>

            </Route>
        </Route>
      </Route>
    <Route
      path="*"
      element={<Navigate to={`${routes.Projects}`} replace={true} />}
    />
  </Route>
  )
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
