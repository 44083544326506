export const Row = [
  {
    "id": 1,
    "day": 1,
    "date": "10/03/2023",
    "default_text_to_pl_group": "Day 1 - terça-feira outubro 3 ,2023",
    "subgroup": "Early Arrivals ",
    "options": " ",
    "options_notes": " ",
    "default_category": "Decor",
    "default_localization": "Lisboa"
  },
  {
    "id": 2,
    "day": 1,
    "date": "10/03/2023",
    "default_text_to_pl_group": "Day 1 - terça-feira outubro 3 ,2023",
    "subgroup": "Meet & Greet Service at Lisbon International Airport",
    "default_category": "----------",
    "default_localization": "Lisboa"
  },
  {
    "id": 3,
    "day": 1,
    "date": "10/03/2023",
    "default_text_to_pl_group": "Day 1 - terça-feira outubro 3 ,2023",
    "subgroup": "Arrival transfer from Lisbon International Airport to Hotel in Lisbon city center",
    "default_category": "F&B - Restaurants",
    "default_localization": "Lisboa"
  },
  {
    "id": 4,
    "day": 2,
    "date": "10/04/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira outubro 4 ,2023",
    "subgroup": "Main Arrivals ",
    "default_category": "Transportation",
    "default_localization": "Lisboa"
  },
  {
    "id": 5,
    "day": 2,
    "date": "10/04/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira outubro 4 ,2023",
    "subgroup": "Meet & Greet Service at Lisbon International Airport",
    "default_category": "Activities",
    "default_localization": "Lisboa"
  },
  {
    "id": 6,
    "day": 2,
    "date": "10/04/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira outubro 4 ,2023",
    "subgroup": "Arrival transfer from Lisbon International Airport to Hotel in Lisbon city center",
    "default_category": "F&B - Restaurants",
    "default_localization": "Lisboa"
  },
  {
    "id": 7,
    "day": 3,
    "date": "10/05/2023",
    "default_text_to_pl_group": "Day 3 - quinta-feira outubro 5 ,2023",
    "subgroup": "Energy Booster before Dinner ",
    "default_category": "Transportation",
    "default_localization": "Lisboa"
  },
  {
    "id": 8,
    "day": 3,
    "date": "10/05/2023",
    "default_text_to_pl_group": "Day 3 - quinta-feira outubro 5 ,2023",
    "subgroup": "Networking Dinner at ",
    "default_category": "Transportation",
    "default_localization": "Lisboa"
  }
]