export const Row = [
  {
    "id": 3,
    "project": "Iveco",
    "customer": "Inspire Mice",
    "budget_amount_": "6,000.00 €",
    "estimated_date_": "01/03/2024",
    "contact": "Cristina Alcântara",
    "voqin_status": "Qualified",
    "status_color": "#81d4fa",
    "protected": true,
    "referral": "John Doe",
    "regimen": "Travel Agency",
    "client_agency": "Conference Direct",
    "sales_office": "LetterB",
    "probability": "80%",
    "voqin_points": 17,
    "comments": ""
  },
  {
    "id": 2,
    "project": "SolidWorks Meeting",
    "customer": "Switch event Marketing",
    "budget_amount_": "350,000.00 €",
    "estimated_date_": "09/05/2023",
    "contact": "Maria Campbell",
    "voqin_status": "Qualified",
    "status_color": "#81d4fa",
    "protected": true,
    "referral": "John Doe",
    "regimen": "Travel Agency",
    "client_agency": "Conference Direct",
    "sales_office": "Sales Office",
    "probability": "90%",
    "voqin_points": 17,
    "comments": ""
  },
  {
    "id": 3,
    "project": "ORACLE Meeting",
    "project_number": "PT.VOA.23.0012",
    "customer": "Oracle Corp",
    "budget_amount_": "200,000.00 €",
    "estimated_date_": "09/10/2023",
    "contact": "Julius Marcus",
    "voqin_status": "Unqualified",
    "status_color":"#9fa8da",
    "protected": true,
    "referral": "Jane Doe",
    "regimen": "Travel Agency",
    "client_agency": "Conference Direct",
    "sales_office": "John",
    "probability": "90%",
    "voqin_points": 15,
    "comments": "Not enough points"
  },
  {
    "id": 4,
    "project": "Novabase 2023 Event.",
    "project_number": "PT.VOA.23.0013",
    "customer": "Novabase",
    "budget_amount_": "90,000.00 €",
    "estimated_date_": "09/05/2023",
    "contact": "John da Silva",
    "voqin_status": "To Qualify",
    "status_color": "#f48fb1",
    "protected": true,
    "referral": "John Doe",
    "regimen": "Travel Agency",
    "client_agency": "Conference Direct",
    "sales_office": "Sales Office",
    "probability": "60%",
    "comments": ""
  },
  {
    "id": 5,
    "project": "Rotary Club",
    "customer": "Rotary",
    "budget_amount_": "220,000.00 €",
    "estimated_date_": "09/23/2023",
    "contact": "Bruno Martens",
    "voqin_status": "To Qualify",
    "status_color": "#f48fb1",
    "protected": true,
    "referral": "John Doe",
    "regimen": "Management Fee",
    "client_agency": "Conference Direct",
    "sales_office": "Sales Office",
    "probability": "30%",
    "comments": ""
  },
  {
    "id": 6,
    "project": "IHHV",
    "project_number": "PT.VOA.23.0110 ",
    "customer": "SWM",
    "budget_amount_": "95,000.00 €",
    "estimated_date_": "10/04/2023",
    "contact": "John Melville",
    "voqin_status": "Qualified",
    "status_color": "#81d4fa",
    "protected": true,
    "referral": "John Doe",
    "regimen": "Travel Agency",
    "client_agency": "SWM",
    "sales_office": "Letterb",
    "probability": "60%",
    "voqin_points": 33,
    "comments": ""
  },
  {
    "id": 7,
    "project": "Ellucian President's Club",
    "project_number": "PT.VOA.23.0025",
    "customer": "Conference Direct",
    "budget_amount_": "120,000.00 €",
    "estimated_date_": "10/14/2023",
    "contact": "Clara Costa",
    "voqin_status": "Qualified",
    "status_color": "#81d4fa",
    "protected": true,
    "referral": "John Doe",
    "regimen": "Travel Agency",
    "client_agency": "SWM",
    "sales_office": "Letterb",
    "probability": "60%",
    "voqin_points": 28,
    "comments": ""
  },
  {
    "id": 8,
    "project": "YPO Global Sales Meeting Cascais ",
    "project_number": "PT.VOA.23.0062",
    "customer": " YPO Canada",
    "budget_amount_": "25,000.00 €",
    "estimated_date_": "10/21/2023",
    "contact": "Alma Emmanuel",
    "voqin_status": "Qualified",
    "status_color": "#81d4fa",
    "protected": true,
    "referral": "John Doe",
    "regimen": "Travel Agency",
    "client_agency": "SWM",
    "sales_office": "Letterb",
    "probability": "60%",
    "voqin_points": 35,
    "comments": ""
  }
]