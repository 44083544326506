export enum ApiRoute {
    signin = 'signin',
    signup = 'signup',
    signout = 'signout',
    getenv = 'getenv',
    getusers = 'getusers',
    getprojects = 'getprojects',
    getprojectinfo = 'getprojectinfo',
    getprojectitems = 'getprojectitems',
    getprojectstats = 'getprojectstats',
    getprojectkpi = 'getprojectkpi',
    getsuppliers = 'getsuppliers',
    getspreadsheet = 'getspreadsheet',
    getprojectsuppliers = 'getprojectsuppliers',
    getallsuppliers = 'getallsuppliers',

    getemployees = 'getemployees',
    createemployees = 'createemployees',
    updateemployees = 'updateemployees',
    deleteemployees = 'deleteemployees',
}

export enum ApiErrorCode {
    BAD_ARGUMENTS = 101,
    NO_DATA = 102,
    LOGIN_NOT_UNIQUE = 201,
    DB_CONNECTION_FAILED = 301
}

export enum SupplierStatus {
    Approved = 100,
    Rejected = 500,
    Awaiting = 300,
    Deadline = 200
}