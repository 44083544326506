import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Pic1 from './print_results.png';


interface IPropsTestModal {
    open: boolean
    onClose: Function
}

const Modal = (props: IPropsTestModal) => {
    const [open, setOpen] = useState<boolean>(props.open)

    useEffect(() => setOpen(props.open), [props.open])

    const onClose = (): void => props.onClose()

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogContent sx={{ padding: 4 }}>
                    <img src={Pic1} style={{ width: 810 }} />
                </DialogContent>
                <DialogActions sx={{ padding: 4 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onClose} variant='contained'>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Modal
