import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Supplier",
    type: types.Text,
    name: "supplier",
    required: true
  },
  {
    title: "Gift Type",
    type: types.Text,
    name: "gift_type",
    required: true
  },
  {
    title: "Ranking",
    type: types.Text,
    name: "ranking",
    required: true
  }
];
