import React, {useState} from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import Fields from 'components/EditForm/Fields'
import {types} from 'enums/fields'
import {IComponent, Value} from 'components/EditForm/interfaces'

const EditForm = (props: IComponent) => {
    const [values, setValues] = useState<{[key: string]: Value}>(props.initialValues)

    const onChange = (name: string, value: Value) => {
        const dependency = props.config.find(field => field.optionsDependency === name)

        if (typeof props.getAutocomplete === 'function') {
            const field = props.config.find(field => field.name === name)
            const autocomplete = field ? field.autocomplete : false

            if (autocomplete) {
                if (value) {
                    const data = props.getAutocomplete(name, value)

                    if (data) {
                        setValues({
                            ...values,
                            ...data,
                            [name]: value
                        })
                    } else {
                        setValues({
                            ...values,
                            [name]: value
                        })
                    }
                } else {
                    setValues({
                        ...props.initialValues,
                        [name]: value
                    })
                }

                return
            }
        }

        if (dependency) {
            const valueInit = dependency.type === types.Text ? '' : null

            setValues({
                ...values,
                [dependency.name]: valueInit,
                [name]: value
            })
        } else {
            setValues({
                ...values,
                [name]: value
            })
        }
    }

    const getOptions = (name: string, dependencyField?: string) => {
        if (typeof props.getOptions === 'function') {
            const dependencyValue = dependencyField ? values[dependencyField] : undefined
            return props.getOptions(name, dependencyField, dependencyValue)
        } else {
            return []
        }
    }

    const handleConfirm = () => {
        props.handleConfirm(values)
    }

    const disabled = !!props.config.find(field => field.required && !values[field.name])
    const autocomplete = !!props.config.find(field => field.autocomplete && values[field.name])
    
    return (
        <Dialog open={props.open} scroll='body' fullWidth onClose={props.handleClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.description &&
                    <DialogContentText sx={{marginBottom: 2}}>
                        {props.description}
                    </DialogContentText>
                }
                {props.config.map(item => {
                    const options = item.type === types.Chip || item.type === types.Chips ?
                        getOptions(item.name, item.optionsDependency) : []

                    return <Fields
                        key={item.name}
                        value={values[item.name]}
                        name={item.name}
                        title={item.title}
                        type={item.type}
                        min={item.min ? values[item.min] : undefined}
                        max={item.max ? values[item.max] : undefined}
                        onChange={onChange}
                        options={options}
                        disabled={autocomplete && !item.autocomplete}
                    />
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleRanking} variant='contained'>Ranking</Button>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={handleConfirm} variant='contained' disabled={disabled}>Confirm</Button>
            </DialogActions>
      </Dialog>
    )
}

export default EditForm