import React, { useState } from 'react'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import { Row } from './row'
import Column from './column'
import ModalRow1 from './ModalRow1'


const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78

const CriteriaRanking = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [checked, setCheck] = useState<number[]>([])
    const handleSelect = (rowIds: number[]) => setCheck(rowIds)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;

    const handleFormOpen = (rowIndex: number) => {
        if(rowIndex >= 0){
        const oneIndexedRowIndex = convertTo1Indexed(rowIndex);
        setSelectedRowIndex(oneIndexedRowIndex);
        setModalOpen(true);
        }
      };
    
    const selectedRow = selectedRowIndex !== null ? Row[selectedRowIndex] : null;

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['Procurement', 'Category Ranking']} />
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>


            <Spreadsheet
                order={[]}
                editableHeader={false}
                fixedCellWidth={100}
                checkedRows={checked}
                propId='id'
                rows={Row}
                columns={Column.columns}
                heightRow={50}
                onCheck={handleSelect}
                renderCell={renderCell}
                onRowClick={(rowId) => handleFormOpen(rowId)}
            /> 

            <ModalRow1
              open={modalOpen}
              onClose={() => setModalOpen(false)}
            ></ModalRow1>

            </Box>
        </React.Fragment>
    )
}

export default CriteriaRanking
