export const Row = [
  {
    "id": 1,
    "supplier": "Feeders",
    "zone": "Portugal, Spain",
    "type": "Architecture",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "MultiLeme",
    "zone": "Portugal, Spain",
    "type": "Architecture",
    "ranking": "89%"
  }
]