import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Employee",
    type: types.Text,
    name: "employee",
    required: true
  },
  {
    title: "Manager",
    type: types.Text,
    name: "manager",
    required: true
  },
  {
    title: "Role",
    type: types.Text,
    name: "role",
    required: true
  },
  {
    title: "Department",
    type: types.Text,
    name: "department",
    required: true
  },
  {
    title: "#Complete",
    type: types.Text,
    name: "complete",
    required: true
  }
];
