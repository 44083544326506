const Column = {
  columns: [
{
  name: "employee",
  title: "Employee",
  width: 170,
  type: "text"
},
{
  name: "role",
  title: "Role",
  width: 170,
  type: "text"
},
{
  name: "department",
  title: "Department",
  width: 170,
  type: "text"
},
{
  name: "questionnaires_published",
  title: "Questionnaires Published",
  width: 240,
  type: "text"
}
  ]
}

export default Column