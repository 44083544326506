export const Row = [
  {
    "id": 1,
    "supplier": "Artist Key",
    "zone": "Lisboa, Porto. Algarve",
    "type": "Artist,Folk Group, Speaker",
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "NeweEvents",
    "zone": "Lisboa, Porto. Algarve",
    "type": "Artist,Folk Group, Speaker",
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Manager do P.Portas",
    "zone": "Lisboa, Porto. Algarve",
    "type": "Speaker",
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "EZISpeakers",
    "zone": "Lisboa, Porto. Algarve",
    "type": "Speaker",
    "ranking": "85%"
  }
]