import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Question Code",
    type: types.Text,
    name: "question_code",
    required: true
  },
  {
    title: "Header",
    type: types.Text,
    name: "header",
    required: true
  },
  {
    title: "Question",
    type: types.Text,
    name: "question",
    required: true
  },
  {
    title: "Order",
    type: types.Text,
    name: "order",
    required: true
  },
  {
    title: "Question Type",
    type: types.Chip,
    name: "type",
    required: true
  },
];
