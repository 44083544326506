import React, { useMemo, useState } from 'react';
import { Box, Chip, Fab, Tooltip } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';
import { Row } from './row';
import Column from './column';

import EditForm from 'components/EditForm';
import { formConfig } from './formConfig';
import { Chart as ChartJS, ArcElement, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import { TaskAlt } from '@mui/icons-material';
import Modal from './modal';

ChartJS.register(ArcElement, Legend, BarElement, CategoryScale, LinearScale)

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const icon = {
    fontSize: 18
}

const chip = {
    marginLeft: 2,
    cursor: 'pointer',
    width: '150px'
}



const CurrentEvaluation = () => {
    const [checked, setCheck] = useState<number[]>([]);
    const handleSelect = (rowIds: number[]) => setCheck(rowIds);
    const [addTemplate, setAddTemplate] = useState<boolean>(false)

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;
    const selectedRow = selectedRowIndex !== -1 ? Row[selectedRowIndex] : null;

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormConfirm = () => {
      handleFormClose()
    }

    const handleAddTemplate = () => {
        setAddTemplate(true)
      }

      const handleFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }


    const initialValues = useMemo(() => {
        if (selectedRow) {
            return {
                employee: selectedRow.employee || '',
                manager: selectedRow.manager || '',
                role: selectedRow.role || '',
                department: selectedRow.department || '',
                adaptability: selectedRow.adaptability || '',
                resilience: selectedRow.resilience || '',
                empathy: selectedRow.empathy || null,
                ownership: selectedRow.ownership || null,
                collaborative: selectedRow.collaborative || '',
                team_management: selectedRow.team_management || '',
                leadership: selectedRow.leadership || '',
                voqins_global_vision: selectedRow.voqins_global_vision || '',
                kpi_average: selectedRow.kpi_average || null,
                cultural: selectedRow.cultural || '',
                business: selectedRow.business || '',
                global_fit: selectedRow.global_fit || '',
                completness: selectedRow.completness || '',
            };
        }
        return {
            employee: '',
            manager: '',
            role: '',
            department: '',
            adaptability: '',
            resilience: '',
            empathy: null,
            ownership: null,
            collaborative: '',
            team_management: '',
            leadership: '',
            voqins_global_vision: '',
            kpi_average: null,
            cultural: '',
            business: '',
            global_fit: '',
            completness: '',
        };
    }, [selectedRowIndex]);

    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
                <Breadcrumbs texts={['VOQIN Way', 'Evaluations']} />
                <Chip
                    icon={<TaskAlt sx={icon} />}
                    label={'Close Evaluation Period'}
                    sx={chip}
                    onClick={handleFilterOpen}
                />
            </Box>
            <Box style={{margin:'18px', marginTop: 'auto', marginLeft:'auto'}}>
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={Row}
                    columns={Column.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                    onRowClick={(selectedRow) => {
                        const rowIndex = convertTo1Indexed(selectedRow);
                        setSelectedRowIndex(rowIndex);
                        setModalOpen(true);
                    }}
                />
               <Modal open={modalOpen} onClose={() => setModalOpen(false)} />

                <EditForm
                    open={formOpen}
                    handleClose={handleFormClose}
                    handleConfirm={handleFormConfirm}
                    title={'Edit Voqin Way'}
                    config={formConfig}
                    initialValues={initialValues}
                />
            </Box>
        </React.Fragment>
    );
}

export default CurrentEvaluation;
