import React, { useMemo, useState } from 'react'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import EditForm from 'components/EditForm'
import { Row } from './rowRestautants'
import Column from './columnRestaurants'
import { formConfig } from './formConfigRestaurants'

const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78

const Restaurants = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [checked, setCheck] = useState<number[]>([])
    const handleSelect = (rowIds: number[]) => setCheck(rowIds)

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;

    const handleFormOpen = (rowIndex: number) => {
        if(rowIndex >= 0){
        const oneIndexedRowIndex = convertTo1Indexed(rowIndex);
        setSelectedRowIndex(oneIndexedRowIndex);
        setOpenForm(true);
        }
      };
  
    const handleFormClose = () => {
      setOpenForm(false)
    }
  
    const handleConfirm = () => {
      handleFormClose()
    }
    
    const selectedRow = selectedRowIndex !== null ? Row[selectedRowIndex] : null;

    const initialValues = useMemo(() => {
      if (selectedRow) {
        return {
          supplier: selectedRow.supplier || '',
          zone: selectedRow.zone || '',
          subzone: selectedRow.subzone || '',
          max_capacity: selectedRow.max_capacity || '',
          allows_exclusivity: selectedRow.allows_exclusivity || '',
          private_room: selectedRow.private_room || '',
          volante: selectedRow.volante || '',
          michelin_stars: selectedRow.michelin_stars || '',
          cuisine_type: selectedRow.cuisine_type || '',
          price_level: selectedRow.price_level || '',
          ranking: selectedRow.ranking || '',
        };
      }
      return {
        supplier: '',
        zone: '',
        subzone: '',
        max_capacity:'',
        allows_exclusivity: '',
        private_room: '',
        volante: '',
        michelin_stars: '',
        cuisine_type:'',
        price_level:'',
        ranking:''
      };
    }, [selectedRowIndex]);

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['Search Calibration', 'Restaurants']} />
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
            <Spreadsheet
                order={[]}
                editableHeader={false}
                fixedCellWidth={100}
                checkedRows={checked}
                propId='id'
                rows={Row}
                columns={Column.columns}
                heightRow={50}
                onCheck={handleSelect}
                renderCell={renderCell}
                onRowClick={(rowId) => handleFormOpen(rowId)}
            />
            <EditForm
            open={openForm}
            handleClose={handleFormClose}
            handleConfirm={handleConfirm}
            title={'Edit Category Search'}
            config={formConfig} 
            initialValues={initialValues}                                     
            />
            </Box>
        </React.Fragment>
    )
}

export default Restaurants