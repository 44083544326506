import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Supplier",
    type: types.Text,
    name: "supplier",
    required: true
  },
  {
    title: "Zone",
    type: types.Text,
    name: "zone",
    required: true
  },
  {
    title: "Sub-Zone",
    type: types.Text,
    name: "subzone",
    required: true
  },
  {
    title: "Max.Capacity",
    type: types.Text,
    name: "max_capacity",
    required: true
  },
  {
    title: "Allows exclusivity",
    type: types.Text,
    name: "allows_exclusivity",
    required: true
  },
  {
    title: "Private Room",
    type: types.Text,
    name: "private_room",
    required: true
  },
  {
    title: "Volante",
    type: types.Text,
    name: "volante",
    required: true
  },
  {
    title: "Cuisine Type",
    type: types.Chip,
    name: "cuisine_type",
    required: true
  },
  {
    title: "Price Level",
    type: types.Chip,
    name: "price_level",
    required: true
  },
  {
    title: "Ranking",
    type: types.Text,
    name: "ranking",
    required: true
  }
];
