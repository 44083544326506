import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Person from '@mui/icons-material/Person'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import TaskAlt from '@mui/icons-material/TaskAlt'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {types} from 'components/Card'
import routes from 'enums/routes'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import { getRandomPersonName, random, randomDate, setRandomTime } from '_protolib/Randomizer'
import { SortingType, sortBy } from 'oldcomponents/utils'
import { IDocument } from 'api/interfaces'
import Breadcrumbs from 'components/Breadcrumbs'
import ProjectsProgramConfig from './config'
import ProjectsProgramData from './program-data'

const icon = {
    fontSize: 18
}
const chip = {
    marginLeft: 2,
    cursor: 'pointer',
    width: '150px'
}

const config = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Client',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'client_name'
        },
        {
            label: 'Event Number',
            icon: <ConfirmationNumberIcon sx={icon} />,
            type: types.Text,
            value: 'eventNumber'
        },
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        },
    ]
}

const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78
const SIDEBAR_WIDTH = 0


const Program = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [checked, setCheck] = useState<number[]>([])

    const handleSelect = (rowIds: number[]) => setCheck(rowIds)

    const onClick = useCallback((id: number) => {
        navigate(`${routes.Project}/1/${routes.Profit}?program=218`)
    }, [])


    const handleTeamMemberOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleFilterClose = () => {
        setOpenFilter(undefined)
    }

    const filterItems = (value: string) => {
        handleFilterClose()
    }

    const statuses = {
        planning: {value: 'Planning'},
        accepted: {value: 'Accepted'},
        progress: {value: 'In progress'},
        complete: {value: 'Complete'},
        delayed: {value: 'Delayed'},
    }


    const [history, setHistory] = useState<IDocument[]>([])
    const [members, setMembers] = useState<string[]>([])

    const actions = [
        'Created program item "Transportation from Airport to Hotel"',
        '"Transportation from Airport to Hotel" supplier changed from "Bolt" to "Uber"',
        '"Transportation from Airport to Hotel" added new supplier "Uber"',
    ]

    if (!history.length) {
        const m = [getRandomPersonName(), getRandomPersonName(), getRandomPersonName(), getRandomPersonName(), getRandomPersonName()]
        setMembers(m)
        for (let i = 0; i < 20; i++) {
            history.push({
                id: i + 1,
                date: setRandomTime(randomDate('2023-04-03', '2023-07-03')),
                user: random(m),
                action: random(actions)
            })
        }
    
        setHistory(sortBy(history, 'date', SortingType.Descending))
    }

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['SolidWorks meeting in Lisbon', 'Program']} />
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={ProjectsProgramData}
                    columns={ProjectsProgramConfig.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                    onRowClick={onClick}
                    // collapseProp={'subitems_length'}
                    // totals={totals}
                />
            </Box>
        </React.Fragment>
    )
}

export default Program