const Column = {
  columns: [
{
  name: "supplier",
  title: "Supplier",
  width: 170,
  type: "text"
},
{
  name: "zone",
  title: "Zone",
  width: 170,
  type: "text"
},
{
  name: "max_capacity",
  title: "Max.Capacity",
  width: 170,
  type: "text"
},
{
  name:"furniture",
  title:"Furniture",
  width:170,
  type:"Text"
},
{
  name: "voqin_stars",
  title: "VOQIN Stars",
  width: 170,
  type: "text"
},
{
  name: "ranking",
  title: "Ranking",
  width: 170,
  type: "text"
}
  ]
}

export default Column