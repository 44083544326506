const Column = {
  columns: [
{
  name: "supplier",
  title: "Supplier",
  width: 170,
  type: "text"
},
{
  name: "zone",
  title: "Zone",
  width: 170,
  type: "text"
},
{
  name: "max_capacity",
  title: "Max.Capacity",
  width: 170,
  type: "text"
},
{
  name: "meeting_room",
  title: "Meeting Room",
  width: 170,
  type: "text"
},
{
  name: "max_meeting_room_capacity",
  title: "Max.Meeting Room Capacity",
  width: 170,
  type: "text"
},
{
  name: "stars",
  title: "Stars",
  width: 170,
  type: "text"
},
{
  name: "ranking",
  title: "Ranking",
  width: 170,
  type: "text"
}
  ]
}

export default Column