import {configureStore} from '@reduxjs/toolkit'
import auth from 'slices/auth'
import project from 'slices/project'
import message from 'slices/message'
import ui from 'slices/ui'
import employee from 'slices/employee'

export const store = configureStore({
    reducer: {
        auth: auth.reducer,
        project: project.reducer,
        message: message.reducer,
        ui: ui.reducer,
        employee: employee.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch