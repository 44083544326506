const Column = {
  columns: [
{
  name: "supplier",
  title: "Supplier",
  width: 170,
  type: "text"
},
{
  name: "zone",
  title: "Zone",
  width: 170,
  type: "text"
},
{
  name: "type",
  title: "Type",
  width: 170,
  type: "status"
},
{
  name: "ranking",
  title: "Ranking",
  width: 170,
  type: "text"
}
  ]
}

export default Column