const Column = {
  columns: [
{
  name: "default_questionnaire",
  title: "Default Questionnaire",
  width: 170,
  type: "text"
},
{
  name: "last_update",
  title: "Last Update",
  width: 170,
  type: "text"
}
  ]
}

export default Column