import React, { useMemo, useState } from 'react';
import { Spreadsheet } from '@framelink/spreadsheet_ts';
import renderCell from 'components/SpreadsheetCell';
import { Box, Fab, Tooltip } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import EditForm from 'components/EditForm';
import { Row } from './data/row';
import Column from './data/column';
import { formConfig } from './data/formConfig';
import { Add, AddHomeWorkRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import routes from 'enums/routes';

const TOPBAR_HEIGHT = 64;
const PANEL_HEIGHT = 78;

const OverviewTemplate = () => {

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [checked, setCheck] = useState<number[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const navigate = useNavigate();
  const handleAddProgramItem = () => setModalOpen(true);
  const handleCloseProgramItem = () => setModalOpen(false);
  const handleConfirmProgramItem = () => {
    handleCloseProgramItem();
  }
  const confirmButton = () => {
    navigate(`${routes.Project}/1/${routes.Profit}`)
  }
  const handleSelect = (rowIds: number[]) => setCheck(rowIds);

  const convertTo1Indexed = (rowId: number) => rowId - 1;

  const handleFormOpen = (rowIndex: number) => {
    if (rowIndex >= 0) {
      const oneIndexedRowIndex = convertTo1Indexed(rowIndex);
      setSelectedRowIndex(oneIndexedRowIndex);
      setOpenForm(true);
    }
  };

  const handleFormClose = () => setOpenForm(false);
  const handleConfirm = () => handleFormClose();

  const selectedRow = selectedRowIndex !== null ? Row[selectedRowIndex] : null;

  const initialValues = useMemo(() => {
    if (selectedRow) {
      return {
        day: selectedRow.day || '',
        date: selectedRow.date || '',
        default_text_to_pl_group: selectedRow.default_text_to_pl_group || '',
        options: selectedRow.options || '',
        options_notes: selectedRow.options_notes || '',
        program_item: selectedRow.program_item || '',
        default_category: selectedRow.default_category || null,
        default_localization: selectedRow.default_localization || null,
      };
    }
    return {
      day: '',
      date: '',
      default_text_to_pl_group: '',
      options: '',
      options_notes: '',
      program_item: '',
      default_category: null,
      default_localization: null,
    };
  }, [selectedRowIndex]);

  return (
    <React.Fragment>
      <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
        <Breadcrumbs texts={['Meeting Template', 'Overview']} />
      </Box>

      <Box style={{ display: 'flex', height: TOPBAR_HEIGHT }}>
        <Tooltip title="Add Program Item" placement="top">
          <Fab onClick={() => handleAddProgramItem()} color="secondary" size="small" sx={{ marginLeft: '20px' }}>
            <Add />
          </Fab>
        </Tooltip>
        <Tooltip title="Save project" placement="top">
          <Fab onClick={() => confirmButton()} color="secondary" size="small" sx={{ marginLeft: '20px' }}>
            <AddHomeWorkRounded />
          </Fab>
        </Tooltip>
      </Box>

      <Box style={{ height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex' }}>
        <Spreadsheet
          order={[]}
          editableHeader={false}
          fixedCellWidth={100}
          checkedRows={checked}
          propId="id"
          rows={Row}
          columns={Column.columns}
          heightRow={50}
          onCheck={handleSelect}
          renderCell={renderCell}
          onRowClick={(rowId) => handleFormOpen(rowId)}
        />

        <EditForm
          open={openForm}
          handleClose={handleFormClose}
          handleConfirm={handleConfirm}
          title={'Edit Template'}
          config={formConfig}
          initialValues={initialValues}
        />

        <EditForm
          open={modalOpen}
          handleClose={handleCloseProgramItem}
          handleConfirm={handleConfirmProgramItem}
          title={'Add new Program Item'}
          config={formConfig}
          initialValues={{}}
        />

      </Box>
    </React.Fragment>
  );
};

export default OverviewTemplate;

