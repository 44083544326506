const Column = {
  columns: [
{
  name: "supplier",
  title: "Supplier",
  width: 170,
  type: "text"
},
{
  name: "zone",
  title: "Zone",
  width: 170,
  type: "text"
},
{
  name: "subzone",
  title: "Sub-Zone",
  width: 170,
  type: "text"
},
{
  name: "max_capacity",
  title: "Max.Capacity",
  width: 170,
  type: "text"
},
{
  name: "allows_exclusivity",
  title: "Allows exclusivity",
  width: 170,
  type: "text"
},
{
  name: "private_room",
  title: "Private Room",
  width: 170,
  type: "text"
},
{
  name: "volante",
  title: "Volante",
  width: 170,
  type: "text"
},
{
  name: "cuisine_type",
  title: "Cuisine Type",
  width: 170,
  type: "client"
},
{
  name: "price_level",
  title: "Price Level",
  width: 170,
  type: "status"
},
{
  name: "ranking",
  title: "Ranking",
  width: 170,
  type: "text"
}
  ]
}

export default Column