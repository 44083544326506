import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Employee",
    type: types.Text,
    name: "employee",
    required: true
  },
  {
    title: "Manager",
    type: types.Text,
    name: "manager",
    required: true
  },
  {
    title: "Role",
    type: types.Text,
    name: "role",
    required: true
  },
  {
    title: "Department",
    type: types.Text,
    name: "department",
    required: true
  },
  {
    title: "Adaptability",
    type: types.Text,
    name: "adaptability",
    required: true
  },
  {
    title: "Resilience",
    type: types.Text,
    name: "resilience",
    required: true
  },
  {
    title: "Empathy",
    type: types.Text,
    name: "empathy",
    required: true
  },
  {
    title: "Ownership",
    type: types.Text,
    name: "ownership",
    required: true
  },
  {
    title: "Collaborative",
    type: types.Text,
    name: "collaborative",
    required: true
  },
  {
    title: "Team Management\n",
    type: types.Text,
    name: "team_management",
    required: true
  },
  {
    title: "Leadership\n",
    type: types.Text,
    name: "leadership",
    required: true
  },
  {
    title: "VOQIN's Global Vision ",
    type: types.Text,
    name: "voqins_global_vision",
    required: true
  },
  {
    title: "KPI Average",
    type: types.Text,
    name: "kpi_average",
    required: true
  },
  {
    title: "Cultural",
    type: types.Text,
    name: "cultural",
    required: true
  },
  {
    title: "Business",
    type: types.Text,
    name: "business",
    required: true
  },
  {
    title: "Global Fit",
    type: types.Text,
    name: "global_fit",
    required: true
  },
  {
    title: "Completness",
    type: types.Text,
    name: "completness",
    required: true
  }
];
