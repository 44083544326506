const Column = {
  columns: [
{
  name: "employee",
  title: "Employee",
  width: 170,
  type: "text"
},
  ]
}

export default Column