export const Row = [
  {
    "id": 1,
    "evaluee": "John Carpenter",
    "evaluator": "John Carpenter",
    "type": "Self",
    "role": "CCO",
    "complete": "Complete",
    "status_color": "#a5d6a7",
  },
  {
    "id": 2,
    "evaluee": "Marc Rees",
    "evaluator": "John Carpenter",
    "type": "Peers",
    "role": "COO",
    "complete": "Complete",
    "status_color": "#a5d6a7",
  },
  {
    "id": 3,
    "evaluee": "Susan Vega",
    "evaluator": "John Carpenter",
    "type": "Peers",
    "role": "CFO",
    "complete": "Complete",
    "status_color": "#a5d6a7",
  },
  {
    "id": 4,
    "evaluee": "Robert, Palmer",
    "evaluator": "John Carpenter",
    "type": "Direct Report",
    "role": "Sales Director",
    "complete": "Complete",
    "status_color": "#a5d6a7",
  },
  {
    "id": 5,
    "evaluee": "Randy, Heather",
    "evaluator": "John Carpenter",
    "type": "Direct Report",
    "role": "Sales Specialist",
    "complete": "To Do",
    "status_color": "#81d4fa",
  },
  {
    "id": 6,
    "evaluee": "Manuel Castro",
    "evaluator": "John Carpenter",
    "type": "Direct Report",
    "role": "Team Member",
    "complete": "Complete",
    "status_color": "#a5d6a7",
  }
]