export const Row = [
  {
    "id": 1,
    "employee": "John Carpenter",
    "manager": "William Jones",
    "role": "CCO",
    "department": "Sales",
    "start_date": "01/01/2008",
    "level": 1
  },
  {
    "id": 2,
    "employee": "Randy, Heather",
    "manager": "John Carpenter",
    "role": "Sales Specialist",
    "department": "Sales",
    "start_date": "10/15/2012",
    "level": 2
  },
  {
    "id": 3,
    "employee": "William Jones",
    "role": "CEO",
    "start_date": "10/15/2012",
    "level": 0
  },
  {
    "id": 4,
    "employee": "Robert, Palmer",
    "manager": "John Carpenter",
    "role": "Sales Director",
    "department": "Sales",
    "start_date": "01/12/2014",
    "level": 2
  },
  {
    "id": 5,
    "employee": "Randy, Heather",
    "manager": "John Carpenter",
    "role": "Sales Specialist",
    "department": "Sales",
    "start_date": "01/02/2014",
    "level": 2
  },
  {
    "id": 6,
    "employee": "Alvin, Silva",
    "manager": "Marc Rees",
    "role": "Project Manager",
    "department": "Projects Dept",
    "start_date": "01/05/2014",
    "level": 2
  },
  {
    "id": 7,
    "employee": "Marc Rees",
    "manager": "William Jones",
    "role": "COO",
    "department": "Projects Dept",
    "start_date": "01/05/2014",
    "level": 1
  },
  {
    "id": 8,
    "employee": "Pat Heather",
    "manager": "Marc Rees",
    "role": "Project Coordinator",
    "department": "Projects Dept",
    "start_date": "01/05/2014",
    "level": 2
  },
  {
    "id": 9,
    "employee": "Mike Siri",
    "manager": "Pat Heather",
    "role": "Team Leader",
    "department": "Projects Dept",
    "start_date": "01/05/2014",
    "level": 3
  },
  {
    "id": 10,
    "employee": "Kay  Star",
    "manager": "Mike Siri",
    "role": "Team member",
    "department": "Projects Dept",
    "start_date": "01/05/2014",
    "level": 4
  },
  {
    "id": 11,
    "employee": "Susan Vega",
    "manager": "William Jones",
    "role": "CFO",
    "department": "Finance",
    "start_date": "01/05/2014",
    "level": 1
  },
  {
    "id": 12,
    "employee": "Carol Viegas",
    "manager": "Susan Vega",
    "role": "Financial Controler",
    "department": "Finance",
    "start_date": "01/05/2014",
    "level": 2
  },
  {
    "id": 13,
    "employee": "Marc Foster",
    "manager": "Mike Siri",
    "role": "Team member",
    "department": "Projects Dept",
    "start_date": "01/05/2014",
    "level": 4
  },
  {
    "id": 14,
    "employee": "Wlliam Defoe",
    "manager": "Susan Vega",
    "role": "Susan Vega",
    "department": "Finance",
    "start_date": "01/05/2014",
    "level": 2
  }
]
