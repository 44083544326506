export const Row = [
  {
    "id": 1,
    "supplier": "Penha Longa",
    "zone": "Lisbon Coast",
    "max_capacity": 100,
    "meeting_room": "yes",
    "max_meeting_room_capacity": 200,
    "stars": 5,
    "ranking": "90%"
  },
  {
    "id": 2,
    "supplier": "Ritz 4 seasons",
    "zone": "Lisboa",
    "max_capacity": 500,
    "meeting_room": "yes",
    "max_meeting_room_capacity": 200,
    "stars": 5,
    "ranking": "89%"
  },
  {
    "id": 3,
    "supplier": "Hyatt Regency Lisboa",
    "zone": "Lisboa",
    "stars": 5,
    "ranking": "85%"
  },
  {
    "id": 4,
    "supplier": "Avenida Palace",
    "zone": "Lisboa",
    "stars": 5,
    "ranking": "85%"
  },
  {
    "id": 5,
    "supplier": "Valverde Hotel",
    "zone": "Lisboa",
    "meeting_room": "no",
    "stars": 4,
    "ranking": "83%"
  },
  {
    "id": 6,
    "supplier": "Real Villa Itália",
    "zone": "Lisbon Coast",
    "stars": 5,
    "ranking": "82%"
  },
  {
    "id": 7,
    "supplier": "Tivoli Seteais",
    "zone": "Lisbon Coast",
    "max_capacity": 50,
    "meeting_room": "yes",
    "max_meeting_room_capacity": 50,
    "stars": 5,
    "ranking": "82%"
  }
]