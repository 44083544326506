import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Employee",
    type: types.Text,
    name: "employee",
    required: true
  },
  {
    title: "Role",
    type: types.Text,
    name: "role",
    required: true
  },
  {
    title: "Department",
    type: types.Text,
    name: "department",
    required: true
  },
  {
    title: "Questionnaires Published",
    type: types.Text,
    name: "questionnaires_published",
    required: true
  }
];
