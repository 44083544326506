export default {
    Signin: '/signin',

    ImportFromCRM: '/icrm',
    Projects: '/projects',
    Procurement: '/procurement',
    Templates: '/templates',

    Suppliers: '/suppliers',
    Members: '/members',
    Voqinway:'/voqinway',

    Project: '/project',
    Overview: 'overview',
    Profit: 'profit',
    Program: 'program',
    ProjectHistory: 'projecthistory',
    ProjectGhant: 'projectghant',
    SiteInspections: 'site_inspections',
    RiskAssessment: 'risk_assessment',
    Invoices: 'invoices',
    Balance: 'balance',
    Actions: 'actions',

    RankingCriteria: 'rankingcriteria',
    Calibration: 'calibration',

    Accomodation: 'accomodation',
    Catering: 'catering',
    Restaurants: 'restaurants',
    Transportation: 'transportation',
    Activities: 'activities',
    Venues: 'venues',
    Decor: 'decor',
    AudioVisual: 'audiovisual',
    Entertainment: 'entertainment',
    Gifts: 'gifts',
    Staff: 'staff',
    Production: 'production',
    Others: 'others',

    Template: '/template',
    OverviewTemplate: 'meeting',

    VoqinSetup: 'setup',
    Prepare: 'prepare',
    Answer: 'answer',
    Results: 'results',

    EvaluationPeriods: 'evaluationperiods',
    DefaultEvaluations: 'defaultevaluations',

    ChiefCLevel: 'chiefclevel',

    QuestionnairesPublished: 'questionnairespublished',
     
    Published:'published',

    Evaluator: 'evaluator',
    Evaluee:'evaluee',

    Evaluation: 'evaluation',
    PastEvaluations: 'pastevaluations',
    Progress: 'progress',

    PastEvaluationsPeriods:'pastevaluationsperiods',

    
    
   
   
   
   
   
   
   
   

    

    Dashboards: '/dashboards',
    
    
    
    





    
    ProductLaunchesDefaultProject: 'product_launch',



    MyQuestionaires: 'myquestionaires',

    



    JohnCarpenterEvaluation: 'johncarpenterevaluation',

    Voqineers: 'voqineers',
    Countries: 'countries',

    Mid2023: 'mid2023portugal',

    JohnCarpenter: 'johncarpenter',
    MyEvaluations:'myevaluations',
    DirectEvaluations: 'directevaluations',
    RandyHeather:'randy',
    QuestionCode:'question',

    
    
}