import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Evaluation Period",
    type: types.Text,
    name: "evaluation_period",
    required: true
  },
];
