import { useEffect, useState } from "react";
import { Spreadsheet } from "@framelink/spreadsheet_ts";
import { useAppDispatch, useAppSelector } from 'app/hooks';
import renderCell from "../../components/SpreadsheetCell";
import { getProjectItems } from "slices/project";
import { getSheetProjectItems } from "slices/ui";
import React from "react";
import ModalSelectSupplier from "oldcomponents/ModalSelectSupplier";
import { useSearchParams } from "react-router-dom";
import ModalProfitLossItem from "oldcomponents/ModalProfitLossItem";
import MockProjectItems from "data/project-items";
import MockSpreadsheetPnl from "data/spreadsheet-pnl";
import MockProjectItemsAccepted from "data/project-items-accepted";

const SpreadsheetProfitLoss = (props: { projectId: number }) => {
    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams();
    const programId = searchParams.get("program")
    const accepted = searchParams.get("accepted") === 'true'

    const [items, setItems] = useState(accepted ? MockProjectItemsAccepted : MockProjectItems)
    // if (accepted) {
    //     setItems(MockProjectItemsAccepted)
    // } else {
    //     setItems(MockProjectItems)
    // }
    console.log(accepted)
    console.log(items)
    // const items = useAppSelector(state => state.project.projectItems)
    const spreadsheet = MockSpreadsheetPnl
    // const spreadsheet = useAppSelector(state => state.ui.sheetProjectItems)

    const [modalOpenSelectSupplier, setModalOpenSelectSupplier] = useState<boolean>(false)

    const [modalOpenProfitLossItem, setModalOpenProfitLossItem] = useState<boolean>(false)

    const [checked, setCheck] = useState<number[]>([])

    useEffect(() => {
        // dispatch(getProjectItems(props.projectId))
        // dispatch(getSheetProjectItems())
    }, [])

    const handleSelect = (rowIds: number[]) => setCheck(rowIds)

    const getSubrows = (id: number) => {
        const item: any = (items as any).find((item: { id: number; }) => item.id === id)
        if (item && item.suppliers) {
            return item.suppliers
        }
        return []
    }

    const totals: any = {
        item_participants: 1,
        item_gross: 0,
        item_total: 0,
        item_totalWTaxes: 0,
        item_invoiceId: 0,
        item_byingCost: 0,
        // item_profitPercent: 0,
        item_supplierComissions: 0,
        // item_vat: 0,
        item_netPayable: 0,
        item_totalPayable: 0,
        item_netBuyingCost: 0,
        item_totalCost: 0,
        item_profit: 0,
        // item_profitMargin: 0,
        item_comissionsDeducted: 0,
        item_comissionsConsidered: 0
    }

    let rows = items

    if (items) {
        if (programId) {
            rows = (items as any).filter((i: any) => i.programName === '21 May - Welcome reception at the hotel')
        }
        const keys = Object.keys(totals)
        items.forEach((item: any) => {
            keys.forEach((key: string) => {
                if (item[key]) {
                    totals[key] = totals[key] + Number(item[key])
                }
            })
        })
    }

    return spreadsheet ? (
        <React.Fragment>
            <ModalSelectSupplier
                open={modalOpenSelectSupplier}
                onClose={() => setModalOpenSelectSupplier(false)}
                />
            <ModalProfitLossItem
                accepted={accepted}
                open={modalOpenProfitLossItem}
                onClose={() => setModalOpenProfitLossItem(false)}
                />
            <Spreadsheet
                order={[]}
                editableHeader={false}
                propId='id'
                rows={rows}
                columns={spreadsheet.columns}
                heightRow={50}
                onRowClick={() => { setModalOpenProfitLossItem(true) }}
                onCheck={handleSelect}
                checkedRows={checked}
                collapseProp={spreadsheet.propCollapseCount}
                onExpand={getSubrows}
                onIconClick={() => setModalOpenSelectSupplier(true)}
                fixedCellWidth={120}
                renderCell={renderCell}
                totals={totals}
            />
        </React.Fragment>
    ) : null
}

export default SpreadsheetProfitLoss
