import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Supplier",
    type: types.Text,
    name: "supplier",
    required: true
  },
  {
    title: "Zone",
    type: types.Text,
    name: "zone",
    required: true
  },
  {
    title: "Max.Capacity",
    type: types.Text,
    name: "max_capacity",
    required: true
  },
  {
    title:"Furniture",
    type: types.Text,
    name:"furniture",
    required: true
  },
  {
    title: "VOQIN Stars",
    type: types.Text,
    name: "voqin_stars",
    required: true
  },
  {
  title: "Ranking",
  type: types.Text,
  name: "ranking",
  required: true
  }
];
