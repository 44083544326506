export const Row = [
  {
    "id": 1,
    "day": 1,
    "date": "14/2/2023",
    "default_text_to_pl_group": "Day 1 - terça-feira fevereiro 14 ,2023",
    "program_item": "Business Meeting at real Vila Itália Hotel",
    "default_category": "Decor",
    "default_localization": "Lisboa"
  },
  {
    "id": 2,
    "day": 2,
    "date": "15/2/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira fevereiro 15 ,2023",
    "program_item": "Business Meeting at Real Vila Itália Hotel",
    "default_category": "----------",
    "default_localization": "Lisboa"
  },
  {
    "id": 3,
    "day": 2,
    "date": "15/2/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira fevereiro 15 ,2023",
    "program_item": "Side Programme - Optional Activities from 2.00 - 6.30PM",
    "default_category": "F&B - Restaurants",
    "default_localization": "Lisboa"
  },
  {
    "id": 4,
    "day": 2,
    "date": "15/2/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira fevereiro 15 ,2023",
    "options": "Walking Food and Wine Tour",
    "options_notes": " ",
    "default_category": "Transportation",
    "default_localization": "Lisboa"
  },
  {
    "id": 5,
    "day": 2,
    "date": "15/2/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira fevereiro 15 ,2023",
    "options": "Walking Tour of Alfama and Sao Jorge ",
    "default_category": "Activities",
    "default_localization": "Lisboa"
  },
  {
    "id": 6,
    "day": 2,
    "date": "15/2/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira fevereiro 15 ,2023",
    "options": "Explore on my own (transportation to/from Lisbon, but no planned activity)",
    "default_category": "F&B - Restaurants",
    "default_localization": "Lisboa"
  },
  {
    "id": 7,
    "day": 2,
    "date": "15/2/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira fevereiro 15 ,2023",
    "options": "Pasteis de Nata Baking Class ",
    "default_category": "Transportation",
    "default_localization": "Lisboa"
  },
  {
    "id": 8,
    "day": 2,
    "date": "15/2/2023",
    "default_text_to_pl_group": "Day 2 - quarta-feira fevereiro 15 ,2023",
    "program_item": "Overnight",
    "default_category": "Transportation",
    "default_localization": "Lisboa"
  }
]